export default {
  route: {
    main: 'Główna',
    dashboard: 'Daszbord',
    dashboards: 'Daszbordy',
    sales: 'Sprzedaż',
    calendar: 'Kalendarz',
    tasks: 'Zadania',
    salesOrders: 'Zamówienia klientów',
    products: 'Produkty',
    counterparties: 'Kontrahenci',
    counterparty: 'Kontrahent',
    costomers: 'Klienci',
    costomer: 'Klient',
    carriers: 'Przewoźnicy',
    carrier: 'Przewoźnik',
    vendorsAndCustomers: 'Dostawcy i odbiorcy',
    reclamations: 'Reklamacje',
    reclamationviewform: 'Reklamacja (podgląd)',
    purchase: 'Zakup',
    userProfile: 'Profil użytkownika',
    timeTracking: 'Licznik czasu',
    registrationprotocol: 'Protokół rejestracji',
    reclCatalogs: 'Słowniki',
    reclSubjects: 'Przedmioty zleceń',
    statusGroups: 'Grupy statusów',
    reclStates: 'Statusy zleceń',
    reclPerpetrators: 'Odpowiedzialni za reklamacje',
    reclTypes: 'Typy zleceń',
    reclExecutions: 'Realizacja',
    reclReasons: 'Przyczyny reklamacji',
    reclDecisions: 'Decyzje',
    costArticles: 'Rodzaje kosztów',
    costArticle: 'Rodzaj kosztów',
    deliveryTypes: 'Rodzaje wysyłki',
    financialPeriods: 'Terminy finansowe',
    shipment: 'Wysyłka',
    settlements: 'Rozrachunki',
    deliveryType: 'Rodzaj wysyłki',
    settings: 'Ustawienia',
    firms: 'Firmy',
    firm: 'Firma',
    ships: 'Statki',
    shippingOfComplaintItems: 'Wysyłka elementów reklamacyjnych',
    goToParentDocuments: 'Przejdź do powiązanych dokumentów',
    scales: 'Wagi',
    event: 'Wydarzenie',
    events: 'Wydarzenia',
    salesPoints: 'Punkty sprzedaży',
    users: 'Użytkownicy',
    usersList: 'Lista użytkowników',
    accessGroups: 'Grupy uprawnień',
    otherSettings: 'Inne ustawienia',
    administration: 'Administrowanie',
    emailTemplates: 'Szablony email',
    numerators: 'Numeratory dokumentów',
    executors: 'Wykonawcy zadań',
    executorRoles: 'Role wykonawców',
    exchangeObjects: 'Obiekty do wymiany',
    taskTemplates: 'Szablony zadań',
    customerRequests: 'Zapytania ofertowe',
    crStatuses: 'Statusy zapytań ofertowych',
    crStatuse: 'Status zapytania ofertowego',
    customerRequestStatuses: 'Statusy zapytań ofertowych',
    mailbox: 'Skrzynka pocztowa',
    salesOrder: 'Zamówienie klienta',
    customerRequest: 'Zapytanie ofertowe',
    customerRequestAnalytics: 'Analityka zapytania ofertowe',
    newEmail: 'Nowy e-mail',
    emailDetails: 'E-mail',
    outgoingEmailDetails: 'E-mail wysłany',
    task: 'Zadanie',
    product: 'Dane pozycji',
    customer: 'Dane klienta',
    accessGroup: 'Grupa uprawnień',
    user: 'Użytkownik',
    executor: 'Wykonawca zadań',
    executorRole: 'Rola wykonawcy',
    taskTemplate: 'Szablon zadań',
    reclamation: 'Reklamacja',
    reclamationStatistic: 'Statystyki reklamacyjne',
    emailAccounts: 'Konta e-mail',
    emailAccount: 'Konto e-mail',
    crTags: 'Tagi zapytań ofertowych',
    crTag: 'Tag zapytania ofertowego',
    customerRequestTags: 'Tagi zapytań ofertowych',
    usersAndAccess: 'Użytkownicy i uprawnienia',
    bpAndTasks: 'Procesy biznesowe i zadania',
    bpDefinitions: 'Definicja procesów biznesowych',
    bpDefinition: 'Definicja procesu biznesowego',
    bpExemplars: 'Procesy biznesowe',
    employees: 'Pracownicy',
    employee: 'Pracownik',
    report: 'Raport',
    reports: 'Raporty',
    salesOrderStatuses: 'Statusy zamówień klientów',
    salesOrderStatus: 'Status zamówienia klienta',
    currencies: 'Waluty',
    currency: 'Waluta',
    countries: 'Kraje',
    country: 'Kraj',
    termsOfPayment: 'Terminy płatności',
    pricelists: 'Cenniki produkcyjne',
    pricelist: 'Cennik produkcyjny',
    discounts: 'Rabaty produkcyjne',
    discount: 'Rabat produkcyjny',
    documentPrefixes: 'Prefiksy dokumentów',
    interactionStatuses: 'Statusy interakcji',
    interactionTags: 'Tagi interakcji',
    interactions: 'Interakcje',
    interaction: 'Interakcja',
    action: 'Akcja',
    userSettings: 'Ustawienia użytkowników',
    navigation: 'Ustawienie nawigacji',
    logistic: 'Logistyka',
    production: 'Produkcja',
    scalesCars: 'Wagi samochodowe',
    usersSettings: 'Ustawienia użytkowników',
    formBuilder: 'Form builder',
    forms: 'Widoki',
    deliveryOrders: 'Zlecenia',
    dispositions: 'Dyspozycje',
    deliveryNotes: 'Listy przewozowe',
    userRoles: 'Role użytkowników',
    userRole: 'Rola użytkownika',
    dispositionStatuses: 'Statusy dyspozycji',
    dispositionStatuse: 'Status dyspozycji',
    contactPersons: 'Osoby kontaktowe',
    contactPerson: 'Osoba kontaktowa',
    assortments: 'Sortymenty',
    assortment: 'Sortyment',
    controlCompanies: 'Firmy kontrolne',
    controlCompany: 'Firma kontrolna',
    mines: 'Kopalnie',
    mine: 'Kopalnia',
    schemesOfCargo: 'Relacje przeładunkowe',
    schemeOfCargo: 'Relacja przeładunkowa',
    ship: 'Statek',
    vehicles: 'Środki transportu',
    vehicle: 'Środek transportu',
    drivers: 'Kierowcy',
    driver: 'Kierowca',
    scale: 'Waga',
    workingPlaces: 'Miejsca pracy',
    scaleProtocols: 'Protokoły wagowe',
    scaleProtocol: 'Protokół wagi',
    positions: 'Pozycje',
    position: 'Pozycja',
    projects: 'Projekty',
    project: 'Projekt',
    orderSubjects: 'Zamówione przedmioty',
    orderSubject: 'Zamówiony przedmiot',
    warehouse: 'Magazyn',
    warehouses: 'Magazyny',
    unitsOfMeasure: 'Jednostki miary',
    unitOfMeasure: 'Jednostka miary',
    weighingStations: 'Stanowiska wagowe',
    weighingStation: 'Stanowisko wagowe',
    appModules: 'Moduły systemu',
    appModule: 'Moduł systemu',
    objectVersioningSettings: 'Ustawienia wersjonowania obiektów',
    objectVersioningSetting: 'Ustawienie wersjonowania obiektu',
    reclamationClientDemands: 'Oczekiwania klienta',
    reclamationClientDemand: 'Oczekiwanie klienta',
    reclamationDecisions: 'Decyzje',
    reclamationDecision: 'Decyzja',
    reclamationExecutionTypes: 'Typy realizacji zleceń',
    reclamationExecutionType: 'Typ realizacji zleceń',
    reclamationPerpetrators: 'Odpowiedzialni za reklamacje',
    reclamationPerpetrator: 'Odpowiedzialny za reklamacje',
    reclamationReasons: 'Przyczyny reklamacji',
    reclamationReason: 'Przyczyna reklamacji',
    reclamationSubjects: 'Przedmioty zleceń',
    reclamationSubject: 'Przedmiot zleceń',
    reclamationTypes: 'Typy zleceń',
    reclamationType: 'Typ zlecenia',
    reclamationStatuses: 'Statusy zleceń',
    reclamationStatus: 'Status zleceń',
    reclamationStatusGroups: 'Grupy statusów',
    reclamationStatusGroup: 'Grupa statusów',
    paymentTypes: 'Rodzaje płatności',
    paymentType: 'Rodzaj płatności',
    cashFlowItems: 'Przepływy środków pieniężnych',
    cashFlowItem: 'Element przepływu środków pieniężnych',
    bankReceipt: 'Przelew wchodzący',
    bankExpense: 'Przelew wychodzący',
    cashReceipt: 'Kasa przyjmie',
    cashExpense: 'Kasa wypłaci',
    emailLabels: 'Etykiety e-mail',
    emailLabel: 'Etykieta e-mail',
    emailTemplateEdition: 'Edycja szablonu email',
    departments: 'Działy',
    department: 'Dział',
    accessRestrictions: 'Ograniczenia dostępu',
    accessRestriction: 'Ograniczenie dostępu',
    eventTypes: 'Typy wydarzeń',
    eventType: 'Typ wydarzenia',
    standings: 'Funkcje pracowników',
    standing: 'Funkcja pracownika',
    eventStatuses: 'Statusy wydarzeń',
    eventStatus: 'Status wydarzenia',
    userSettingItems: 'Definicja ustawień użytkowników',
    userSettingItem: 'Definicja ustawienia użytkownika',
    userSettingGroup: 'Grupa ustawień użytkownika',
    appSettingGroup: 'Grupa ustawień systemu',
    appSettingItem: 'Definicja ustawienia systemu',
    appSettingItems: 'Definicja ustawień systemu',
    appSettings: 'Ustawienia systemu',
    appSetting: 'Ustawienie systemu',
    documentNumerators: 'Numeratory dokumentów',
    viewSettings: 'Ustawienia widoków',
    viewSetting: 'Ustawienie widoku',
    appObjects: 'Obiekty systemu',
    appObject: 'Obiekt systemu',
    availableNavigation: 'Nawigacja dostępna (Menu)',
    remainingViews: 'Pozostałe widoki',
    boxes: 'Komórki magazynowe',
    box: 'Komórka magazynowa',
    exchangeObjectEdition: 'Edycja obiektu wymiany',
    inventoryDocuments: 'Dokumenty magazynowe',
    inventoryStatuses: 'Statusy dokumentów magazynowych',
    inventoryCategories: 'Kategorie dokumentów magazynowych',
    inventoryOperationTypes: 'Rodzaje operacji magazynowych',
    inventoryOperationType: 'Rodzaj operacji magazynowych',
    counterpartyAccountingGroups: 'Grupy księgowe kontrahentów',
    counterpartyAccountingGroup: 'Grupa księgowa kontrahenta',
    vatRates: 'Stawki VAT',
    addUpdateProductionOrders: 'Dodanie / Edycja zleceń produkcyjnych',
    addEditHistory: 'Dodanie / Edycja historii',
    additionalParameters: 'Dodatkowe parametry',
    productVariantDefinitions: 'Definicje wariantów produktu',
    productVariants: 'Warianty produktu',
    productVariant: 'Wariant produktu',
    deliveryRoutes: 'Trasy wysyłek',
    deliveryRoute: 'Trasa wysyłek',
    deliveryScheduleStatuses: 'Statusy planów wysyłek',
    deliveryScheduleStatus: 'Status planów wysyłek',
    localities: 'Miejscowości',
    locality: 'Miejscowość',
    deliverySchedules: 'Plany wysyłek',
    deliverySchedule: 'Plan wysyłki',
    punishmentsTypes: 'Rodzaje opłat za korzystanie z parkingu',
    punishmentType: 'Rodzaj opłat za korzystanie z parkingu',
    punishments: 'Opłaty za korzystanie z parkingu',
    punishment: 'Opłata za korzystanie z parkingu',
    reportSettings: 'Ustawienia raportów',
    reportSetting: 'Ustawienie raportu',
    roleGroups: 'Grupy uprawnień',
    accessLogs: 'Dzenniki rejestracji',
    deliveryPayments: 'Zapłaty za korzystanie z parkingu',
    deliveryPayment: 'Zapłata za korzystanie z parkingu',
    orderTypes: 'Typy zamówień',
    orderType: 'Typ zamówienia',
    outgoingSms: 'Wychodzące SMS-y',
    counterpartyAddresses: 'Adresy kontrahentów',
    counterpartyAddress: 'Adres kontrahenta',
    userNavigation: 'Ustawienie nawigacji użytkowników',
    ordersForTransports: 'Zamówienia na transport',
    orderForTransport: 'Zamówienie na transport',
    exchangeRates: 'Kursy walut',
    exchangeRate: 'Kurs waluty',
    carsQueueManagment: 'Zarządzanie kolejką samochodów',
    exchangeNodes: 'Węzły wymiany',
    bom: 'BOM',
    boms: 'BOM',
    reglamentTasks: 'Zadania reglamentowe',
    reglamentTask: 'Zadanie reglamentowe',
    documentationForms: 'Formy prowadzenia dokumentacji',
    documentationForm: 'Dokumentacja księgowa',
    legalForms: 'Formy prawne',
    legalForm: 'Forma prawna',
    taxations: 'Opodatkowania',
    taxation: 'Opodatkowanie',
    mainDesktopSettings: 'Ustawienie pulpitu głównego',
    loadingPlan: 'Plan załadunku statku',
    loadingPlans: 'Plany załadunku statków',
    storagePeriods: 'Okresy przechowywania',
    storagePeriod: 'Okres przechowywania',
    salesPrice: 'Cennik',
    salesPrices: 'Cenniki',
    serviceAgreement: 'Akt świadczenia usług',
    serviceAgreements: 'Akty świadczenia usług',
    shipRegistration: 'Rejestracja statku',
    shipRegistrations: 'Rejestracje statków',
    worksheet: 'Karta pracy',
    worksheets: 'Karty pracy',
    shipLoading: 'Ładowanie statku',
    shipLoadings: 'Ładowanie statków',
    work: 'Wykonywana praca',
    works: 'Wykonywane prace',
    equipments: 'Sprzęt',
    equipment: 'Sprzęt',
    serviceCharge: 'Opłata za usługi',
    serviceCharges: 'Opłaty za usługi',
    enums: 'Enumeracje',
    enum: 'Enumeracja',
    components: 'Komponenty',
    component: 'Komponent',
    weightingsHistories: 'Historia wag',
    weightingsHistory: 'Historia wag',
    printForm: 'Formularz wydruku',
    scheduledJob: 'Regulaminowe zadanie',
    restApi: 'Rest API',
    shipsPlanning: 'Planowanie statku',
    filesManager: 'Menedżer plików',
  },
  navbar: {
    logOut: 'Wyloguj',
    clearAll: 'Wyczyść wszystkie',
    profile: 'Mój profil',
    newNotes: 'Nowe powiadomienia',
    checkAll: 'Sprawdż wszystkie',
    hello: 'Witaj!',
    notifications: 'Powiadomienia',
  },
  valueTypes: {
    number: 'Liczba',
    date: 'Data',
    string: 'Tekst',
    boolean: 'Logiczne',
    ref: 'Odnośnik',
  },
  login: {
    description: 'Wpisz swój login i hasło, aby uzyskać dostęp do panelu administracyjnego',
    username: 'Użytkownik',
    usernamePh: 'Wpisz swój login',
    invalidLogin: 'Źle podany login (e-mail) użytkownika lub Hasło',
    unexpectedError: 'Wystąpił nieoczekiwany błąd, spróbuj ponownie',
    password: 'Hasło',
    passwordPh: 'Wprowadż hasło',
    forgotPassword: 'Nie pamiętasz hasła?',
    logIn: 'Zaloguj się',
    noHaveAccount: 'Nie masz konta?',
    register: 'Zarejestruj się',
    rememberMe: 'Zapamiętaj mnie',
    securityCheck: 'Kontrola bezpieczeństwa',
    sendNewCode: 'Wyślij nowy kod',
    authCode: 'Kod weryfikacyjny',
    authCodePh: 'Wprowadż kod weryfikacyjny',
    invalidAuthCode: 'Błędnie wprowadzony kod weryfikacyjny',
    outOfDateCode: 'Kod weryfikacyjny wygasł. Poproś o nowy kod.',
    authDescription: 'Wpisz swój kod weryfikacyjny, aby kontynuować logowanie',
  },
  register: {
    title: 'Rejestracja użytkownika',
    firmTitle: 'Rejestracja firmy',
    titleDescription: 'Nie masz konta? Zarejestruj się, zajmie to mniej niż minutę',
    firstLastName: 'Imię i nazwisko',
    name: 'Imię',
    surename: 'Nazwisko',
    enterDetailsPh: 'Wprowadż swoje dane',
    email: 'E-mail',
    emailPh: 'Wprowadż swój e-mail',
    login: 'Login',
    loginPh: 'Wprowadż swój login',
    terms: 'Warunki korzystania z portalu',
    register: 'Zarejestruj się',
    haveAccount: 'Już masz konto?',
    confirm: 'Twoje konto zostało pomyślnie zarejestrowane',
    notFilledAllFilds: 'Nie wypełnione wszystkie pola',
    sendTo: 'E-mail został wysłany na',
    emailCheck: 'Sprawdź wiadomość e-mail od portalu i kliknij dołączony link, aby potwierdzić e-mail.',
    backHome: 'Wróć na stronę główną',
    emailConfirmed: 'E-mail potwierdzony',
    emailNotConfirmed: 'Nie udało się potwierdzić e-maila',
    resendYourEmail: 'Wyślij ponownie swój e-mail',
    enterValidEmail: 'Wpisz poprawny adres e-mail',
    emailSent: 'Wiadomość e-mail wysłana pomyślnie. Proszę, sprawdź swoją skrzynkę.',
    emailSentError: 'Wystąpił błąd podczas próby wysłania wiadomości e-mail. Proszę spróbować później.',
    passwordLinkSent: 'Link do zmiany hasła został wysłany na wskazany e-mail.',
    enterNewPassword: 'Wpisz nowe hasło',
    enterPasswordLimit: 'Wpisz co najmniej 7 znaków.',
    reenterPassword: 'Wprowadź ponownie hasło',
    reenterPasswordError: 'Powinno być zgodne z wprowadzonym hasłem.',
    reenterEmail: 'Wprowadź ponownie swój adres e-mail, dla nowego linku.',
    resendEmail: 'Wyślij ponownie e-mail',
    canResetPasswordMsg: 'Możesz teraz zmienić swoje hasło.',
    resetPasswordLate: 'Wygląda na to, że jesteś trochę za późno. Spróbuj ponownie wysłać wiadomość e-mail.',
    resetPasswordError: 'Wystąpił błąd podczas realizacji Twojej prośby. Spróbuj ponownie wysłać informacje dotyczące resetowania.',
    firmName: 'Nazwa firmy',
    firmNamePh: 'Wprowadź nazwę firmy',
    country: 'Kraj',
    countryPh: 'Wprowadź kraj',
    companyDepartment: 'Dział firmy',
    companyDepartmentPh: 'Wprowadź dział firmy',
    commercialInChargeOfYou: 'Odpowiedzialny za ciebie handlowiec',
    commercialInChargeOfYouPh: 'Wprowadź odpowiedzialnego za ciebie handlowca',
    notVatNumber: 'Nie posiadam wewnętrznego numeru VAT',
    phone: 'Telefon',
    phonePh: 'Wprowadź numer telefonu',
    vatNumber: 'Węwnętrzny numer VAT',
    vatNumberPh: 'Wprowadź wewnętrzny numer VAT',
    manager: 'Menedżer',
    firmAddress: 'Adres firmy',
    firmAddressPh: 'Wprowadź adres firmy',
    address: 'Adres',
    addressPh: 'Wprowadź adres',
    postalCode: 'Kod pocztowy',
    postalCodePh: 'Wprowadź kod pocztowy',
    city: 'Miasto',
    cityPh: 'Wprowadź miasto',
    deliveryDepartment: 'Dział dostawy',
    deliveryDepartmentPh: 'Wprowadź dział dostawy',
  },
  msg: {
    authCodeSended: 'Nowy kod weryfikacyjny został wysłany.',
    reminderAddedSuccesfully: 'Przypomnienie zostało dodane',
    reminderNotAdded: 'Wypełnij dane poprawnie',
    restore: 'Element zostanie przywrócony!',
    delete: 'Element zostanie zaznaczony do usunięcia!',
    deleteError: 'Błąd usunięcia elementu',
    catalogWriteError: 'Nie udało się zapisać elementu katalogu',
    catalogWriteSuccess: 'Element katalogu został zapisany',
    processStartSuccess: 'Proces rozpoczął się pomyślnie',
    processStartFailure: 'Błąd podczas uruchamiania procesu',
    documentConfirmError: 'Nie udało się zatwierdzić dokument',
    documentConfirmSuccess: 'Dokument został zatwierdzony',
    documentUndoConfirmError: 'Nie udało się anulować zatwierdzenia dokumentu',
    documentConfirmUndoSuccess: 'Zatwierdzenia dokumentu zostało anulowane',
    filingError: 'Dane nie są poprawne',
    noAccess: 'Brak dostępu',
    createTrackingSuccess: 'Obiekt został dodany do trackingu',
    createTrackingError: 'Nie udało się dodać obiektu do trackingu',
    technicalBreak: 'Przerwa techniczna',
    groupHasNestedItems: 'Grupa ma podporządkowane elementy',
    noFoundResults: 'Nie znaleziono wyników dla',
    nothingFound: 'Nie znaleziono wyników',
    nothingSelected: 'Nic nie wybrano',
    searchResultsFor: 'Trwa wyszukiwanie wyników dla',
    startTypingForSearch: 'Zacznij pisać, aby wyszukać.',
    passwordConfirmationError: 'Hasła nie są zgodne',
    noRelatedViewSettings: 'Brak powiązanych ustawień widoków',
    setDefaultSettings: 'Ustawienia użytkownika usunięte.',
    notFoundNextStage: 'Nie można znaleźć następnego etapu.',
    wrongNip: 'Nieprawidłowy format nip',
    countryNotFilled: 'Wypełnij kraj',
    viesTrue: 'Kontrahent jest podatnikiem VAT-UE',
    viesFalse: 'Kontrahent nie jest podatnikiem VAT-UE',
    bpExecuteActionError: 'Nie udało się wykonać akcji.',
    impossibleDelete: 'Niemożliwe usunięcie.',
    noFilesForSend: 'Nie możesz wyslać e-mail. Plik dla klienta nie został załączony.',
    maxLimitRows: 'Przekroczono maksymalny limit danych {limit} wierszy. Proszę zmniejsz zakres.',
    documentWriteSuccess: 'Dokument został zapisany',
    documentConfirmErrorMarekedToDelete: 'Nie udało się zatwierdzić dokument zaznaczony do usunięcia',
  },
  question: {
    closeModifiedView: 'Dane zostały zmienione. Po zamknięciu widoku zmiany będą stracone!',
    businessProcessStart: 'Czy na pewno chcesz rozpocząć proces biznesowy ',
  },
  reclamation: {
    new: 'Nowa reklamacja',
    add: 'Dodaj reklamację',
    addUpdatePositions: 'Dodanie / Edycja pozycji zamówienia reklamacyjnego',
    bulkInteractionForPositions: 'Zbiorcza interakcja na pozycje',
    addOrder: 'Dodaj zlecenie',
    productionJobNumber: 'Numer zadania produkcyjnego',
    open: 'Otwarte',
    ongoing: 'W trakcie',
    toReceive: 'Do odebrania',
    negative: 'Odrzucone',
  },
  customerRequest: {
    title: 'Zapytanie ofertowe',
    new: 'Nowe zapytanie ofertowe',
    newVersion: 'Nowa wersja zapytania ofertowego',
    edit: 'Edycja zapytania ofertowego nr ',
    presentation: 'Zapytanie ofertowe nr {0} od {1}',
    createNew: 'Utwórz zapytanie',
    editStatus: 'Zmiana statusu',
    editDecision: 'Zmiana decyzji',
    addComment: 'Nowy komentarz',
    editResult: 'Edycja wyniku',
    newStatus: 'Wybierz nowy status',
    selectDecision: 'Wybierz decyzję',
    newDecision: 'Nowa decyzja',
    goToAnalytics: 'Przejdź do analityki',
    startBp: 'Uruchom proces uzgodnienia',
    msg: {
      selectItemFirst: 'Wybierz najpierw zapytanie ofertowe!',
      return: 'Zapytanie ofertowe zostanie przywrócone!',
      delete: 'Zapytanie ofertowe zostanie zaznaczone do usunięcia!',
      orderAvailable: 'Zapytanie ofertowe z taką referencja już istnieje: {0}',
      emptyCustomer: 'Niewypełniony klient!',
      emptyReference: 'Niewypełniona referencja!',
      noWrite: 'Zapytanie ofertowe musi być zapisane!',
    },
  },
  interaction: {
    new: 'Nowa interakcja',
    addNewVersion: 'Dodaj nową wersję',
    numberEdition: 'Edycja numeru interakcji',
    number: 'Numer interakcji',
    actualStatus: 'Aktualny status',
    versionsHistory: 'Historia wersji',
    newVersion: 'Nowa wersja interakcji',
    edit: 'Edycja interakcji nr ',
    commentsAndLinkedEmails: 'Komentarze / Powiązane maile',
    tasksAndSalesOrders: 'Zadania / Zamówienia klienta',
    presentation: 'Interakcja nr {0} od {1}',
    addComment: 'Nowy komentarz',
    addEvent: 'Nowe wydarzenie',
    selectFiles: 'Wybierz pliki!',
    attachments: 'Załączniki interakcji',
    msg: {
      selectItemFirst: 'Wybierz najpierw interakcję!',
      return: 'Interakcja zostanie przywrócona!',
      delete: 'Interakcja zostanie zaznaczona do usunięcia!',
      orderAvailable: 'Interakcja z taką referencja już istnieje: {0}',
      emptyCustomer: 'Niewypełniony klient!',
      noWrite: 'Interakcja musi być zapisana!',
    },
  },
  order: {
    title: 'Zamówienia klientów',
    objectTitle: 'Zamówienie',
    new: 'Nowe zlecenie',
    presentation: 'Zlecenie nr {0} od {1}',
    createTask: 'Utwórz zadanie',
    orderName: 'Zlecenie',

    msg: {
      selectOrderFirst: 'Wybierz najpierw zamówienie!',
      return: 'Zamówienie zostanie przywrócone!',
      delete: 'Zamówienie zostanie zaznaczone do usunięcia!',
      orderAvailable: 'Zamówienie z taką referencja już istnieje: {0}',
      confirmRemoveProduct: 'Na pewno chcesz usunąć ten produkt?',
      emptyCustomer: 'Niewypełniony klient!',
      emptyStatus: 'Niewypełniony status!',
      emptyCurrency: 'Niewypełniona waluta!',
      emptyBlock: 'Brak wypełnienia!',
      emptyReference: 'Niewypełniona referencja!',
      emptyDeliveryMethod: 'Niewypełniona metoda dostawy!',
      emptyFields: 'Niewypełnione wszystkie obowiązkowe pola!',
      writeOrder: 'Zamówienie musi być zapisane!',
      writed: 'Zamówienie zostało zapisane',
      confirmDouble: 'Zamówienie z taką referencja już istnieje. Potwierdź, że chcesz przejść do wyceny?',
    },
    status: {
      OFERTA: 'Oferta',
      NEW: 'Nowe zlecenie',
      CONFIRMED: 'Potwierdzone',
      READY_FOR_PRODUCTION: 'Gotowe do produkcji',
      CANCELED: 'Anulowane',
      CLOSED: 'Zamknięte',
    },
    deliveryMethods: {
      STANDARD: 'Standard',
      UNTIL_CERTAIN_DATE: 'Do określonej daty',
      ON_CERTAIN_DATE: 'Na określoną datę',
      VIP: 'VIP',
    },
    packageMaterial: {
      Karton: 'Karton',
      Folia: 'Folia',
    },
    enterReference: 'Wpisz referencję',
    enterProject: 'Wpisz nazwę projektu',
    enterDeliveryMethod: 'Wybierz rodzaj dostawy',
    enterMaterial: 'Wybierz materiał',
    shipmentDays: 'Dni wyjazdu: ',
    roadDays: 'Dni w drodze: ',
    deliveryDays: 'Dni dostawy: ',
    byOrderPeriod: 'Wg. zakresu zamówienia',
    priceDetails: 'Szczegóły cen',
  },
  paymentOperation: {
    title: 'Operacje płatnicze',
    objectTitle: 'Operacja płatnicza',
    new: 'Nowa operacja',
    presentation: 'Operacja płatnicza nr {0} od {1}',
    createTask: 'Utwórz zadanie',
    msg: {
      selectOrderFirst: 'Wybierz najpierw operację!',
      return: 'Operacja płatnicza zostanie przywrócona!',
      delete: 'Operacja płatnicza zostanie zaznaczona do usunięcia!',
      orderAvailable: 'Operacja płatnicza z taką referencja już istnieje: {0}',
      emptyCustomer: 'Niewypełniony klient!',
      emptyCurrency: 'Niewypełniona waluta!',
      emptyReference: 'Niewypełniona referencja!',
      emptyFields: 'Niewypełnione wszystkie obowiązkowe pola!',
      writeOrder: 'Operacja płatnicza musi być zapisana!',
      writed: 'Operacja płatnicza została zapisana',
      confirmDouble: 'Operacja płatnicza z taką referencja już istnieje. Potwierdź, że chcesz przejść do wyceny?',
    },
    enterReference: 'Wpisz referencję',
    enterProject: 'Wpisz nazwę projektu',
  },
  enums: {
    DATA_TYPES: {
      number: 'Liczba',
      date: 'Data',
      text: 'Tekst',
      string: 'Wiersz',
      boolean: 'Logiczne',
      object: 'Obiekt',
      enum: 'Spis',
    },
    TEXT_ALIGNS: {
      left: 'Po lewej',
      right: 'Po prawej',
      center: 'Pośrodku',
    },
    IMPORTANCES: {
      NOT_SET: 'Brak parametru',
      HIGHT: 'Wysoki',
      NORMAL: 'Zwykły',
      LOW: 'Niski',
    },
    ACTIVE_STATUSES: {
      Active: 'Aktywny',
      Blocked: 'Nie aktywny',
    },
    PACKAGE_MATERIALS: {
      Folia: 'Folia',
      Karton: 'Karton',
    },
    OBJECT_TYPES: {
      catalog: 'Katalog',
      document: 'Dokument',
      register: 'Rejestr',
      report: 'Raport',
    },
    VIEW_TYPES: {
      list: 'Lista',
      detail: 'Detaliczny',
      select: 'Wybór',
      other: 'Inny',
    },
    NAV_VIEW_TYPES: {
      list: 'Lista',
      detail: 'Detaliczny',
      report: 'Raport',
      static: 'Statyczny',
    },
    VERSIONING_METHODS: {
      create: 'Przy stworzeniu',
      update: 'Przy aktualizacji',
      confirm: 'Przy zatwierdzeniu',
      all: 'Wszystkie operacje',
    },
    REPORT_PERIOD_TYPES: {
      date: 'Na datę',
      period: 'Za okres',
      without: 'Bez okresu',
    },
    changeTypes: {
      create: 'Dodanie',
      update: 'Zmiana',
    },
    changedTypes: {
      create: 'Dodał',
      update: 'Zmienił',
    },
    navigationPlacings: {
      general: 'Ważne',
      usual: 'Zwykłe',
      other: 'Pozostałe',
    },
    contactTypes: {
      messenger: 'Messenger',
      phone: 'Telefon',
      email: 'E-mail',
      skype: 'Skype',
      social: 'So',
    },
    typesOfPayment: {
      Cash: 'Gotówka',
      Bank: 'Bank',
    },
    statusList: {
      Finished: 'Zamknięty',
      Ongoing: 'W trakcie',
    },
    typesOfMovement: {
      Receipt: 'Przychód',
      Expense: 'Rozchód',
    },
    TRANSPORT_TYPES: {
      Car: 'Samochód',
      Trailer: 'Naczepa / Przyczepa',
      Carriage: 'Wagon',
    },
    TEXT_POSITION: {
      begin: 'Na początku',
      part: 'W części wiersza',
      end: 'Na końcu',
    },
    TYPE_OF_OPERATION: {
      Shipment: 'Wydanie',
      Receipt: 'Przyjęcie',
      Reweighing: 'Przeważanie',
      DirectVariantShipment: 'Wydanie bezpośrednie',
      DirectVariantReceipt: 'Przyjęcie bezpośrednie',
      Resigning: 'Przeformowanie',
      Relocation: 'Przesunięcie',
      ReceiptConteiners: 'Przyjęcie kontenerów',
    },
    TYPE_OF_DOCUMENT: {
      Automobile: 'Samochodowy',
      Railway: 'Kolejowy',
      Water: 'Wodny',
      Internal: 'Wewnętrzny',
    },
    TYPE_OF_SCALE: {
      Gross: 'Bruto',
      Tara: 'Tara',
      GrossAndTara: 'Bruto i tara',
      Bunker: 'Bunker',
      WithoutWeighing: 'Bez ważenia',
    },
    TYPE_OF_DISPOSITION: {
      Loading: 'Załadowcza',
      Unloading: 'Wyładowcza',
    },
    INVENTORY_CATEGORY_TYPE: {
      income: 'Przychód',
      shipment: 'Rozchód',
      transfer: 'Przesunięcie',
    },
    PROJECT_STATUSES: {
      Finished: 'Skończony',
      Ongoing: 'Trwający',
    },
    PAYMENT_TYPE_STATUSES: {
      Finished: 'Skończony',
      Ongoing: 'Trwający',
    },
    TYPE_OF_PAYMENT: {
      Cash: 'Gotówka',
      Bank: 'Bank',
    },
    TYPE_OF_MOVEMENT: {
      Receipt: 'Przychód',
      Expense: 'Rozchód',
    },
    PROTOCOL_SCALE: {
      Mechanical: 'Mechaniczna',
      IoT: 'IoT',
      'TCP/IP': 'TCP/IP',
      Modbus: 'Modbus',
      COM: 'COM',
      S7: 'S7',
    },
    PROTOCOL_SCALE_PARSE: {
      ToledoSimple: 'ToledoSimple',
      ToledoSimpleTwice: 'ToledoSimpleTwice',
      ToledoModbus: 'ToledoModbus',
      IT4000E: 'IT4000E',
    },
    CAR_QUEUE_STATUS: {
      OnTheWay: 'W drodze',
      EntryReservedWithBuffer: 'Rezerwacja w buforze',
      Accepted: 'W kolejce',
      EntryReserved: 'Rezerwacja bez buforu',
      EntryAllowed: 'Wyjazd dozwolony',
      ParkingExit: 'Wyjechał z parkingu',
      TerminalExit: 'Wyjechał z terminalu',
      Deleted: 'Anulowana',
      EntryAddParking: 'Na parkingu badań',
      ExitAddParking: 'Wyjechał z parkingu badań',
      Paused: 'Pauza',
    },
    RESEARCH_RESULT: {
      Positive: 'Próba pozytywna',
      Negative: 'Próba negatywna',
    },
    VEHICLE_TYPE: {
      truck: 'Ciężarowe',
      car: 'Do 3.5t',
    },
    TRANSPORT_WAYS: {
      shippingCompany: 'Firma spedycyjna',
      ourTransport: 'Nasz transport',
    },
    CURRENCY_EXCHANGE_TABLES: {
      A: 'A',
      B: 'B',
      C: 'C',
    },
    EXPRESSIONS: {
      sum: 'Suma',
      min: 'Min',
      max: 'Maks',
      average: 'Średnie',
    },
    SORT_DIRECTIONS: {
      asc: 'Rosnąco',
      desc: 'Malejąco',
    },
    WORK_SHIFTS: {
      First: 'I (23:00 - 07:00)',
      Second: 'II (07:00 - 15:00)',
      Third: 'III (15:00 - 23:00)',
    },
    ITEMS_PRESENTATIONS: {
      titleAndIcon: 'Tytuł i ikona',
      title: 'Tytuł',
      icon: 'Ikona',
    },
    PRODUCT_DOCUMENT_TYPES: {
      Doc1: 'Dokument 1',
      Doc2: 'Dokument 2',
    },
    TOOLBAR_ITEM_TYPES: {
      button: 'Przycisk',
      dropdown: 'Lista rozwijana',
      dropdownItem: 'Element listy',
    },
    BOM_STATUSES: {
      active: 'aktywny',
      draft: 'szkic',
      canceled: 'anulowany',
    },
    NOTIFICATION_TYPES: {
      recipient: 'Odbiorca',
      copy: 'Kopiuj',
      notSend: 'Nie wysyłaj',
    },
    ACCUMULATION_RECORD_TYPES: {
      expense: 'Rozchód',
      receipt: 'Przychód',
    },
    REPORT_GROUP_POSITIONS: {
      rows: 'Wiersze',
      cols: 'Kolumny',
    },
    DELIVERY_NOTE_STATE: {
      OnBrutto: 'Na brutto',
      OnTare: 'Na tarowaniu',
      Returns: 'Powrót',
      Closed: 'Zamknięty',
      ToApproved: 'Do zatwierdzenia',
    },
    CUSTOM_STATE_OF_GOODS: {
      EU: 'Z EU',
      NonEU: 'Z poza EU',
    },
  },
  pricelistKinds: {
    main: 'Podstawowy',
    surcharge: 'Dopłata',
  },
  pricingMethods: {
    formula: 'Formuła',
    fixed: 'Stała cena',
    table: 'Tabela cenowa',
    percent: 'Procent',
    scale: 'Skala cenowa',
  },
  discountTypes: { price: 'Cena', percent: 'Procent', formula: 'Formuła' },
  discountBelongs: {
    main: 'Podstawowy cennik',
    surcharge: 'Dopłaty',
    main_and_surcharge: 'Cennik i dopłaty',
    surcharge_and_price: 'Dopłaty i art. dodatkowe',
    all: 'Wszystko',
  },
  task: {
    executionReceive: 'Przyjmij do wykonania',
    showExecuted: 'Pokaż wykonane',
    showTasks: 'Pokaż zadania',
    showMyne: 'Pokaż moje',
    description: 'Opis zadania',
    executionResult: 'Wynik wykonania zadania',
    newTask: 'Nowe zadanie',
    forExecutorRole: 'Dla roli wykonawcy',
    forExecutor: 'Dla wykonawcy',
    redirection: 'Przekierowanie zadania',
  },
  commands: {
    accept: 'Akceptuję',
    search: 'Szukaj',
    insertNumber: 'Wpisz numer',
    runTimeTracking: 'Uruchom śledzenie czasu',
    markAll: 'Zaznacz wszystkie',
    unmarkAll: 'Odznacz wszystkie',
    showDetail: 'Pokaż szczegóły',
    add: 'Dodaj',
    addNew: 'Dodaj nowy',
    delete: 'Usuń',
    clear: 'Wyczyść',
    enterDescription: 'Wpisz opis',
    cancel: 'Anuluj',
    activate: 'Aktywuj',
    allowManual: 'Edytuj',
    enterNumber: 'Wprowadź numer',
    enterComment: 'Wprowadź komentarz',
    enterSth: 'Wprowadź dane',
    ok: 'OK',
    writeAndClose: 'Zapisz i zamknij',
    completeAllConsts: 'Wypełnij wszystkie koszty',
    addRefreshResult: 'Dodaj / Aktualizuj wynik',
    goToEditing: 'Przejść do edycji',
    goToRegister: 'Przejdź do rejestracji',
    goToParentDocument: 'Przejdź do narzędnego dokumentu',
    addAttachment: 'Dodaj załącznik',
    writeChanges: 'Zapisz zmiany',
    write: 'Zapisz',
    close: 'Zamknij',
    autofill: 'Autouzupełnianie',
    addProduct: 'Dodaj produkt',
    addExtraProduct: 'Dodaj towar luzem',
    addReminder: 'Dodaj przypomnienie',
    addTitle: 'Dodaj tytuł',
    addFile: 'Dodaj plik',
    addToCalendar: 'Dodaj do kalendarza',
    eventsList: 'Lista wydarzeń',
    addGroup: 'Dodaj grupę',
    addPhase: 'Dodaj etap',
    addSetting: 'Dodaj ustawienie',
    select: 'Wybierz',
    selectContactFromList: 'Wybierz kontakt z listy',
    addFromList: 'Dodaj z listy',
    mannualEntry: 'Ręczne wpisanie',
    configProduct: 'Skonfiguruj produkt',
    showRegistrationInfo: 'Podać dane rejestracyjne',
    confirm: 'Zatwierdź',
    undoConfirm: 'Anuluj zatwierdzenie',
    showRegistersRecords: 'Pokaż zapisy w rejestrach',
    confirmSelected: 'Potwierdź wybór',
    confirmPrices: 'Potwierdź ceny',
    calculate: 'Przelicz',
    execute: 'Wykonaj',
    cancelTaskExecution: 'Skasuj wykonanie',
    redirectTaskExecution: 'Wykonaj i przekieruj na następny etap',
    bulkInteractionForSelected: 'Zbiorcza interakcja dla zaznaczonych',
    createOrder: 'Utwórz zamówienie',
    createProcess: 'Utwórz proces',
    runAndClose: 'Uruchom i zamknij',
    update: 'Odśwież',
    download: 'Pobierz',
    edit: 'Edytuj',
    editNumber: 'Edytuj numer',
    addComment: 'Dodaj komentarz',
    writeEmail: 'Napisz email',
    addEvent: 'Dodaj wydarzenie',
    showCanceled: 'Pokaż nie aktywne',
    send: 'Wyślij',
    sendResult: 'Wyślij wynik',
    refresh: 'Odśwież',
    resend: 'Wyślij ponownie',
    reset: 'Resetuj',
    resetPassword: 'Resetuj hasło',
    addNotificationContent: 'Dodaj treść powiadomienia',
    redirect: 'Przekieruj',
    createFrom: 'Utwórz na podstawie',
    print: 'Drukuj',
    more: 'Więcej',
    setStatus: 'Ustaw status',
    sendListToAuthor: 'Wyślij listę sterowników autorowi',
    generate: 'Generuj',
    change: 'Zmień',
    getComputerID: 'Pobierz ID komputera',
    getInfoEquipment: 'Pobierz dane urządzenia',
    attachTo: 'Dołącz do',
    sendNow: 'Wyślij teraz',
    selectAll: 'Wybierz wszystko',
    copy: 'Kopiuj',
    deleteRestore: 'Usuń / Przywróć',
    enterCode: 'Podaj kod',
    showHistory: 'Pokaż historię zmian',
    open: 'Otwórz',
    compare: 'Porównaj',
    advancedSearch: 'Wyszukiwanie zaawansowane',
    addViewSetting: 'Dodaj ustawienie widoku',
    moveUp: 'Do góry',
    moveDown: 'W dół',
    changeView: 'Zmień widok',
    forward: 'Przekieruj',
    setDefault: 'Ustaw domyślne',
    inputTranslations: 'Wprowadź tłumaczenia',
    changeStatus: 'Zmień status',
    changeNumber: 'Zmień numer',
    changeDecision: 'Zmień decyzję',
    openReport: 'Otworz raport',
    salesReport: 'Raport sprzedaży',
    addFields: 'Dodaj pola',
    addField: 'Dodaj pole',
    addFilters: 'Dodaj filtry',
    end: 'Zakończ',
    setPeriod: 'Ustaw okres',
    addCustomer: 'Dodaj klienta',
    planRoute: 'Zaplanuj trasę',
    calculateRoute: 'Oblicz trasę',
    findOptimalRoute: 'Znajdź optymalną trasę',
    copySettingsFromUser: 'Kopiuj ustawienia z innego urzytkownika',
    addAction: 'Dodaj Akcje',
    editAction: 'Edycja Akcje',
    getFileForTranslation: 'Pobierz plik do tłumaczenia',
    loadTranslationFromFile: 'Załaduj tłumaczenie z pliku',
    addItem: 'Dodaj element',
    rowUp: 'W górę',
    rowDown: 'W dół',
    checkVat: 'Sprawdz VAT',
    checkVies: 'Sprawdz VIES',
    showVatInfo: 'Informacja VAT',
    batchesPickup: 'Wybór partii',
    loadItemsFromSpreadSheet: 'Załaduj z arkusza',
    hideField: 'Ukryj pole',
    filterByValue: 'Filtruj wg. wartości pola',
    groupByField: 'Grupuj wg. pola',
    sortByFieldAsc: 'Sortuj rosnąco',
    sortByFieldDesc: 'Sortuj malejąco',
    moveFieldRight: 'Zmieść pole w prawo',
    moveFieldLeft: 'Zmieść pole w lewo',
    sendStartSms: 'Wyślij SMS-a startowego',
    fillStandardFields: 'Wypełnij standardowe pola',
    addTable: 'Dodaj tabelę',
    unloadWagonsToFile: 'Prześlij wagony do pliku',
    loadWagonsDataFromFile: 'Załaduj dane wagonów z pliku',
    setSchedule: 'Ustaw harmonogram',
    fill: 'Wypełnij',
    addFolder: 'Dodaj folder',
    editFolder: 'Edytuj folder',
    uploadFiles: 'Prześlij pliki',
    downloadFile: 'Pobierz plik',
    openFile: 'Otwórz plik',
    processes: 'Procesy',
    runningProcesses: 'Procesy uruchomione',
    run: 'Uruchom',
    sortByName: 'Sortuj wg nazwy',
    sortByDate: 'Sortuj wg daty',
    editAccess: 'Zmień uprawnienia',
    editFields: 'Edytuj pola',
    editFilters: 'Edytuj filtry',
    takeFromAnotherUser: 'Przenieś z innego użytkownika',
    addCondition: 'Dodaj warunek',
    showAttachedFiles: 'Pokaż powiązane pliki',
  },
  common: {
    multipleFiles: 'Kilka plików',
    fieldsManager: 'Definicja pół',
    filtersManager: 'Definicja filtrów',
    searchCounterpartyAccountingGroup: 'Wyszukaj grupu księgowu kontrahenta',
    searchProductAccountingGroup: 'Wyszukaj grupu księgowu produktu',
    searchLead: 'Wyszukaj leada',
    searchContactPerson: 'Wyszukaj osobę kontaktową',
    searchAcquisitionChannel: 'Wyszukaj kanał pozyskania',
    searchSupplier: 'Wyszukaj dostawcę...',
    demand: 'Zapotrzebowania',
    searchCounterparty: 'Wyszukaj kontrahenta...',
    search: 'Szukaj...',
    filter: 'Filter',
    filters: 'Filtry',
    missingParameter: 'Brak',
    eventType: 'Typ wydarzenia',
    eventEnd: 'Koniec wydarzenia',
    eventStart: 'Początek wydarzenia',
    message: 'Wiadomości',
    notSendMessage: "Nie chcę dostawać wiadomości od 'Synergy'",
    sendMessageFromSpecific: 'Chcę dostawać wiadomości od wybranych maili',
    start: 'Start',
    firm: 'Firma',
    analyzeProccess: 'Analiza procesu',
    modalTitle: 'Uwaga!',
    emptyField: '<nie wypełnione>',
    setZero: 'Zerowanie',
    setZeroReceipt: 'Zerowanie kwitu',
    userState: 'Status użytkownika',
    technicalData: 'Dane techniczne',
    writeComment: 'Wpisz komentarz',
    selectLanguage: 'Wybierz język',
    currency: 'Waluta',
    mainData: 'Dane podstawowe',
    financialPeriod: 'Terminy finansowe',
    products: 'Produkty',
    files: 'Pliki',
    isFirm: 'Jestem firmą',
    notEmptyField: 'To pole nie może być puste',
    addUpdateCostsOfComplaintOrder: 'Dodanie / Edycja kosztów zlecenia reklamacyjnego',
    selectDate: 'Wybierz datę',
    enterComment: 'Wpisz uwagi',
    enterQuantity: 'Wpisz ilość',
    fileDescription: 'Opis pliku',
    dispositionOfNumber: 'Dyspozycja nr ',
    deliveryOrderOfNumber: 'Zlecenie nr ',
    issuedIn: ' od ',
    total: 'Razem',
    paid: 'Zaplacono',
    leftToPay: 'Pozostalo do zaplaty',
    receiveVATPerson: 'Osoba upoważniona do otrzymania faktury VAT',
    issueVATPerson: 'Osoba upoważniona do wystawienia faktury VAT',
    complaintVerificationProtocol: 'Protokół weryfikacji reklamacji',
    registration: 'ZGŁOSZENIE',
    undefined: 'Nie określono',
    customerFiles: 'Pliki klienta',
    selectFileTitle: 'Wybierz plik!',
    selectProductTitle: 'Wybierz produkt!',
    noSelectedFiles: 'Nie wybrano plików',
    searchCustomer: 'Wyszukaj klienta',
    searchDepartment: 'Wyszukaj dział',
    searchAuthor: 'Wyszukaj autora',
    searchAssortment: 'Wyszukaj sortyment',
    searchAddress: 'Wyszukaj adres',
    searchDriver: 'Wyszukaj kierowcę',
    searchDirector: 'Wyszukaj kierownika',
    searchDispAuthor: 'Wyszukaj autora dyspozycji',
    searchOrderAuthor: 'Wyszukaj autora zlecenia',
    searchWeighingStation: 'Wyszukaj stanowisko wagowe',
    searchVehicle: 'Wyszukaj samochód',
    searchOriginWarehouse: 'Wyszukaj miejsce założenia',
    searchWarehouse: 'Wyszukaj magazyn',
    chooseCarrier: 'Wybierz przewoźnika',
    searchCategory: 'Wyszukaj kategorię',
    searchControlCompany: 'Wyszukaj firmę kontrolną',
    searchOpType: 'Wyszukaj typ operacji',
    searchProducts: 'Wyszukaj produkty',
    searchProductTo: 'Wyszukaj produkt do',
    searchProductVariant: 'Wyszukaj wariant produktu',
    searchWarehouseTo: 'Wyszukaj magazyn do',
    searchWarehouseFrom: 'Wyszukaj magazyn od',
    searchWarehouseSector: 'Wyszukaj sektor magazynu',
    searchWarehouseNumber: 'Wyszukaj numer magazynowy',
    searchForwarder: 'Wyszukaj spedytora',
    searchScale: 'Wyszukaj wagę',
    searchShip: 'Wyszukaj statek',
    searchRelation: 'Wyszukaj relację',
    searchStation: 'Wyszukaj stanowisko',
    searchProduct: 'Wyszukaj towar',
    searchManager: 'Wyszukaj handlowca',
    searchBase: 'Wyszukaj podstawę',
    searchDirective: 'Wyszukaj zlecenie',
    searchOrder: 'Wyszukaj zamówienie',
    searchCustomerOrder: 'Wyszukaj zamówienie klienta',
    searchExecution: 'Wyszukaj wykonanie',
    searchExecutor: 'Wyszukaj wykonawcę',
    searchNettoExecutor: 'Wyszukaj wykonawcę netto',
    searchTaraExecutor: 'Wyszukaj wykonawcę tary',
    searchGrossExecutor: 'Wyszukaj wykonawcę brutto',
    searchConstructor: 'Wyszukaj konstruktora',
    searchTaskExecutor: 'Wyszukaj wykonawcę zadań',
    searchOwner: 'Wyszukaj właściciela',
    searchRail: 'Wyszukaj wagon',
    searchCarrier: 'Wyszukaj przewoźnika',
    searchCurrency: 'Wyszukaj walutę',
    searchCountry: 'Wyszukaj kraj',
    searchLocality: 'Wyszukaj miejscowość',
    scanCode: 'Zeskanuj kod',
    scanTicket: 'Zeskanuj bilet',
    scanBarCode: 'Zeksanuj kod kreskowy',
    searchPaymentDueDate: 'Wyszukaj terminy płatności',
    customerRequestNumberEdit: 'Edycja numeru zapytania ofertowego',
    customerRequestNumber: 'Numer zapytania ofertowego',
    searchContractor: 'Wyszukaj kontrahenta',
    searchType: 'Wyszukaj typ',
    searchBasicDocument: 'Wyszukaj podstawowy dokument',
    searchProject: 'Wyszukaj projekt',
    searchRealization: 'Wyszukaj realizację',
    justification: 'Uzasadnienie',
    searchAgreement: 'Wyszukaj umowę',
    rows: 'wiersze',
    searchSubject: 'Wyszukaj przedmiot',
    searchDecision: 'Wyszukaj decyzję',
    searchResponsible: 'Wyszukaj odpowiedzialnego',
    searchDeliveryOrder: 'Wyszukaj zlecenję',
    searchDisposition: 'Wyszukaj dyspozycję',
    searchReason: 'Wyszukaj powód',
    searchTrailer: 'Wyszukaj naczepę',
    searchExpectation: 'Wyszukaj oczekiwanie',
    searchStatus: 'Wyszukaj status',
    searchVendor: 'Wyszukaj dostawcę',
    searchPaymentType: 'Wyszukaj rodzaj płatności',
    searchCashFlowElement: 'Wyszukaj element przepływu środków pieniężnych',
    searchPosition: 'Wyszukaj pozycję',
    searchUnitOfMeassure: 'Wyszukaj jednostkę',
    searchMine: 'Wyszukaj kopalnię',
    searchVendorCustomer: 'Wyszukaj odbiorcę',
    searchCompletion: 'Wyszukaj realizację',
    searchTrain: 'Wyszukaj pociąg',
    double: 'Duble',
    incorrectData: 'W polu wprowadzono nieprawidłowe dane!',
    from: 'od',
    to: 'do',
    noFound: '<nie znaleziono>',
    selected: 'Wybrane',
    numbers: 'Numery',
    algorithm: 'Algorytm obliczania',
    addinDimensions: 'Dodatkowe wymiary',
    numberOfPieces: 'Liczba sztuk',
    length: 'Długość',
    sales: 'SPRZEDAŻ',
    orders: 'Zamówienia',
    width: 'Szerokość',
    minWidth: 'Szerokość (min)',
    maxWidth: 'Szerokość (max)',
    lengthWidth: 'Długość/Szerokość',
    height: 'Wysokość',
    recalculation: 'Przeliczenie',
    active: 'Aktywny',
    deactive: 'Nieaktywny',
    shipData: 'Dane statku',
    carrierData: 'Dane przewoźnika',
    productData: 'Dane Produktu',
    dateAdded: 'Data dodania',
    regestrationDate: 'Data rejestracji',
    picturePath: 'Ścieżka do obrazka',
    filePath: 'Ścieżka do pliku',
    decisionDate: 'Data decyzji',
    enterPictureName: 'Wpisz nazwę obrazka',
    describeProduct: 'Opisz produkt...',
    customerData: 'Dane Klienta',
    administration: 'Administracja',
    erp: 'ERP',
    executorData: 'Dane Wykonawcy zadań',
    executorRoleData: 'Dane Roli wykonawców',
    complaintsInfo: 'Dane reklamacji',
    emptyExecutorList: 'Lista wykonawców jest pusta...',
    emptyUserList: 'Lista użytkowników jest pusta...',
    emptyHistoryList: 'Nie znaleziono zapisów dot. zmian objektu...',
    days: 'Dni',
    allDays: 'Cały dzień',
    hours: 'Godziny',
    minutes: 'Minuty',
    seconds: 'Sekundy',
    companyRegistrationInfo: 'Dane firmy (z formularza rejestracji)',
    executionToday: 'Wykonanie dziasiaj',
    taskTemplatesData: 'Dane szablonu zadań',
    fillByTemplate: '-- Wypełnij z szablonu --',
    enterName: 'Wpisz nazwę',
    selectImportance: 'Wybierz ważność',
    selectExecutorRole: 'Wybierz rolę wykonawcy',
    selectExecutor: 'Wybierz wykonawcę',
    recepientsCondition: 'Warunek dla odbiorcy',
    executionCondition: 'Warunek wysyłki',
    history: 'Historia',
    yes: 'Tak',
    no: 'Nie',
    planedCompletitionDate: 'Planowana data realizacji',
    selectForm: 'Wybierz dokument',
    selectPossition: 'Wybierz pozycję',
    searchItem: 'Szukaj',
    searchIndustry: 'Wybierz branżę',
    enterTextHere: 'Wpisz tekst tutaj...',
    prices: 'Ceny',
    perPage: 'Limit',
    msg: 'Powiadomienie',
    containsWords: 'Zawiera słowa',
    subsystem: 'Podsystem',
    partition: 'Rozdział',
    view: 'Widok',
    preview: 'Podgląd',
    orderData: 'Dane zlecenia',
    dispositionData: 'Dane dyspozycji',
    deliveryNoteData: 'Dane listu przewozowego',
    vendorsAndCustomers: 'Dostawcy i odbiorcy',
    vendorsAndCustomersData: 'Dane dostawców i odbiorców',
    notSelected: 'Nie wybrano',
    msges: {
      selectItemFirst: 'Wybierz najpierw zapytanie ofertowe!',
    },
    settings: 'Ustawienia',
    general: 'Ogólne',
    externalCodes: 'Kody zewnętrzne',
    forwarders: 'Spedytorzy',
    vehicle: 'Samochód/wagon',
    valueType: 'Typ wartości',
    register: 'Rejestr',
    actions: 'Działania',
    showMore: 'Pokaż więcej',
    processingError: 'Powstał błąd podczas wykonania operacji',
    selectUser: 'Wybierz użytkownika',
    selectMethod: 'Wybierz metodę',
    selectEmployee: 'Wybierz pracownika',
    signatureSettings: 'Ustawienia email użytkownuka',
    defaultPrinterSettings: 'Ustawienia domyślne drukarki',
    localSystem: 'Lokalna instalacja',
    textPosition: 'Położenie w tekście',
    views: 'Widoki',
    navigation: 'Nawigacja',
    fields: 'Pola',
    constructor: 'Konstruktor',
    quickFilter: 'Szybki filter',
    quickFilters: 'Szybkie filtry',
    formatter: 'Formatowanie',
    format: 'Format',
    availableFields: 'Dostępne pola',
    noFieldsAvailable: 'Brak dostępnych pól',
    noFiltersAvailable: 'Brak dostępnych filtrów',
    noGroupsAvailable: 'Brak dostępnych grupowań',
    noSortingAvailable: 'Brak dostępnych sortowań',
    noCalcsAvailable: 'Brak dostępnych wyliczeń',
    noFilters: 'Brak filtrów',
    sortable: 'Sortowalne',
    filterable: 'Filtrowalne',
    groupable: 'Grupowalne',
    calculated: 'Wyliczane',
    items: 'Elementy',
    help: 'Pomoc',
    wastes: 'Odpady',
    taras: 'Tara',
    reservations: 'Rezerwacje',
    loading: 'Ładowanie',
    additionalProducts: 'Dodatkowe produkty',
    styles: 'Style',
    classes: 'Klasy',
    fillTranslationsFromDictionary: 'Wypełnij tłumaczenia ze słownika',
    all: 'Wszystkie',
    onlyEmpty: 'Tylko puste',
    selectedFields: 'Wybrane pola',
    selectedFilters: 'Wybrane filtry',
    details: 'Szczegóły',
    statisticByTraders: 'Statystyki wg handlowców',
    statisticCustomersRequestsByTraders: 'Statystyki wg handlowców - Zapytania ofertowe',
    statisticSalesOrdersByTraders: 'Statystyki wg handlowców - Zamówieńia klienta',
    analizeByCustomers: 'Analiza wg klientów',
    myCalendar: 'Mój kalendarz',
    addCalendar: 'Dodaj kalendarz',
    addDisposition: 'Dodaj dyzpozycję',
    shared: 'Wspólny',
    private: 'Prywatny',
    attendees: 'Uczestnicy spotkania',
    totalProjects: 'Wszystkich projektów',
    totalTasks: 'Wszystkich zadań',
    members: 'Członkowie',
    productivity: 'Produkcyjność',
    customerRequestsByCustomer: 'Zapytania ofertowe wg klientów',
    salesOrdersByCustomer: 'Zamówieńia klienta wg klientów',
    customerRequestsStatistics: 'Statystyka zapytań ofertowych',
    customerRequestsSalesOrdersStatistics: 'Statystyka zapytań ofertowych - zamówień klienta',
    customersValue: 'Nowi klienci',
    customerRequestsValue: 'Wartość zapytań ofertowych',
    salesOrdersValue: 'Wartość zamówień klientów',
    customerRequestsSalesOrdersValue: 'Wartość zapytań ofertowych - zamówień klienta',
    currentWeek: 'Bieżący tydzień',
    previousWeek: 'Poprzedni tydzień',
    weekNumber: 'Numer tygodnia',
    enterWeekNumber: 'Wpisz numer tygodnia',
    actual: 'Faktyczne',
    projection: 'Projekcja',
    notWeighting: 'Oczekiwanie na ważenie',
    beginWeighting: 'Trwa ważenie',
    stopWeighting: 'Ważenie zatrzymane',
    endWeighting: 'Ważenie zakończone',
    customerQuantity: 'Dodano nowych klientów',
    interactionQuantity: 'Dodano interakcje',
    customerRequestsQuantity: 'Ilość nowych zapytań ofertowych',
    salesOrdersQuantity: 'Ilość nowych zamówień klientów',
    customerRequestsValueActual: 'Wartość nowych zapytań ofertowych',
    salesOrdersValueActual: 'Wartość nowych zamówień klientów',
    sinceLastMonth: 'Od ostatniego miesiąca',
    invoiceProFormPl: 'Faktura proforma (pl)',
    invoiceProFormEn: 'Faktura proforma (en)',
    invoiceServiceAgreements: 'Faktura VAT nr',
    anyQuestion: 'Masz pytania?',
    dateOfIssue: 'Data wystawienia',
    seller: 'Sprzedawca',
    bankAccount: 'Konto bankowe',
    buyer: 'Nabywca',
    productionOrder: 'Zlecenie',
    productServiceName: 'Nazwa towaru/usługi',
    bruttoPrice: 'Cena brutto',
    bruttoWorth: 'Wartość brutto',
    nettoWorth: 'Wartość netto',
    worth: 'Wartość',
    inWords: 'Słownie',
    documentIssue: 'Dokument wystawił',
    documentReceived: 'Dokument otrzymał',
    unitOfMeasure: 'j.m',
    bankName: 'Nazwa banku',
    fillNew: 'Wypełnij dla nowych',
    newContact: 'Nowy kontakt',
    orderConfirmPrint: 'Potwierdzenie zamówienia',
    required: 'Obowiązkowe',
    labelCols: 'Szerokość tytułu',
    contentCols: 'Szerokość kontentu',
    default: 'Podstawowe',
    links: 'Powiązania',
    selectCustomer: 'Wybierz klienta',
    fieldCols: 'Szerokość pola',
    itemCols: 'Szerokość elementu',
    newRow: 'Nowy wiersz',
    noData: 'Brak danych',
    allFiles: 'Wszystkie pliki',
    query: 'Zapyt',
    newField: 'Nowe pole',
    newItem: 'Nowy element',
    sortDisabled: 'Sortowanie wyłączone',
    groupDisabled: 'Grupowanie wyłączone',
    filterDisabled: 'Filtrowanie wyłączone',
    functionalDisabled: 'Wyliczanie wyłączone',
    textAlign: 'Rozmieszczenie',
    manualEditionRedirect: 'Ręczne przekierowanie edycji',
    calcDisabled: 'Wyliczanie wyłączone',
    databaseTranslation: 'Tłumaczenie interfejsu',
    toolbar: 'Interfejs poleceń',
    toolbarItems: 'Elementy paska poleceń',
    documentToPrint: {
      printDeliveryNote: 'Wydruk kwitu wagowego',
      printDeliveryNoteRoll: 'Wydruk kwitu wagowego (rolka)',
      printDeliveryNoteTurn: 'Wydruk kwitu wagowego ze skrętem',
    },
    noItemsAvailable: 'Brak dostępnych elementów',
    commandName: 'Nazwa polecenia',
    module: 'Moduł',
    onChange: 'Przy zmianie',
    emptyTicketParking: 'Wprowadź numer biletu',
    acceptDispositionParking: 'Akceptacja dyspozycji',
    paymentParking: 'Płatności',
    servicesParking: 'Inne usługi',
    beginningStartParking: '<< Wróć do strony początkowej',
    exitNoDispositionParking: 'Wyjazd z parkingu bez dyspozycji',
    codeConfirmedParking: 'Potwierdź kod',
    codeFromSMSParking: 'Zeskanuj lub wprowadź kod z SMS',
    confirmDriverParking: 'Potwierdzenie kierowcy',
    scanIdCardParking: 'Zeskanuj dowód osobisty',
    enterIdManuallyParking: 'Wprowadź ręcznie dowód osobisty',
    notFoundDriverParking: 'Dane kierowcy nie zostały potwierdzone. Skontaktuj się z obsługą parkingu.',
    checkAllDataParking: 'SPRAWDŹ  WSZYSTKIE DANE',
    correctTheDataParking: 'Popraw dane',
    instructionHasBeenRegisteredParking: 'Dyspozycja została zarejestrowana w kolejce. Czekaj na sms z informacją o wyjeździe!',
    againScanParking: 'Dokument nie został zeskanowany! Zeskanuj jeszcze raz!',
    servicesParkingAdd: 'Oferowane usługi',
    selectedServices: 'Wybrane usługi',
    toatalAmountParking: 'Razem kwota  (Zł)',
    exitWithoutDispositionParking: 'Czy na pewno chcesz wyjechać bez dyspozycji?',
    confirmExitParking: 'Potwierdź wyjazd',
    callForChangeDateDisp: 'Zadzwoń na numer ........... w celu poprawienia danych.',
    payParking: 'Zapłać',
    payCardSuccess: 'Płatność kartą przebiegła pomyślnie',
    payCardWarning: 'Nie udało się zapłacić kartą. Spróbuj ponownie',
    serviceParking: 'Usługa',
    processing: 'Przetwarzanie',
    punishmentParking: 'Kara kierowcy',
    punishmentPayParking: 'Naliczone opłaty',
    unitParking: 'Zł',
    payByCardparking: 'Zapłacić kartą',
    detailsPunishmentParking: 'Szczegóły naliczonych opłat',
    showTotals: 'Pokaż podsumowania',
    additional: 'Dodatkowe',
    addNipParking: 'Wpisz NIP',
    confirmNipParking: 'Potwierdz',
    noConfirmNipParking: 'Potwierdz bez NIP',
    payCardBankParking: 'Zapłać kartą bankową',
    additionalPunishmantParking: 'Opłata dodatkowa za usługi parkingu',
    documentReaderParking: 'Czytnik dokumentów znajduje się z prawej strony Selfboxu',
    instructionOrTicket: 'Numer dyspozycji / biletu',
    notFoundTicket: 'Brak numeru biletu w systemie',
    enterYorTicket: 'Wpisz swój numer biletu',
    notFoundCodeSMS: 'Wprowadzono błędny kod SMS, spróbuj ponownie',
    cancelParking: 'Cofnij',
    addresses: 'Adresy',
    deliveryPoints: 'Miejsca dostawy',
    new: 'Nowy',
    defaultSettings: 'Ustawienia domyślne',
    searchDocumentationForms: 'Wyszukaj formę prowadzenia dokumentacji',
    reclamationDecisionProtocolPrint: 'Protokół decyzji',
    reclamationNotification: 'Zgłoszenie reklamacyjne',
    reclamationDecisionProtocol: 'Protokół decyzji',
    notificationDate: 'Data zgłoszenia',
    salesPoint: 'Punkt sprzedaży',
    montage: 'Montaż',
    reclamationServiceProtocolPrint: 'Protokół serwisowy',
    reclamationServiceProtocol: 'Protokół serwisowy',
    reclamationMileageProtocolPrint: 'Protokół przebiegu',
    reclamationMileageProtocol: 'Protokół przebiegu',
    productionOrders: 'Zlecenia produkcyjne',
    searchLegalForms: 'Wyszukaj formę prawną',
    reclamationRegistrationProtocolPrint: 'Protokół rejestracji',
    reclamationRegistrationProtocol: 'Protokół rejestracji',
    searchTaxations: 'Wyszukaj opodatkowanie',
    nonOracleLoad: 'Nie ładuj do Oracle',
    settlementsData: 'Rozrachunki',
    ticketInfo: 'Informacja o bilecie',
    resetTicket: 'Odwiązać bilet od dyspozycji',
    management: 'Zarządzanie',
    describeAlgorithm: 'Opisz algorytm',
    values: 'Znaczenia',
    noParentSubsystem: 'Brak podsystemu narzędnego',
    noPalacing: 'Brak rozmieszczenia',
    viewItems: 'Elementy widoku',
    objectFields: 'Pola obiektu',
    addinItems: 'Elementy dodatkowe',
    components: 'Komponenty',
    itemsTree: 'Drzewo elementów',
    searchByTicketNumber: 'Wyszukiwanie po numerze biletu',
    emptyDispositionList: 'Nie znaleziono dyspozycji...',
    basic: 'Podstawowe',
    advanced: 'Zaawansowane',
    commands: 'Polecenia',
    itemOffset: 'Odstęp',
    usePagination: 'Użyj paginacji',
    maxHeight: 'Maksymalna wysokość, px',
    rowsLimit: 'Wierszy na stronę',
    printSettings: 'Ustawienia wydruku',
    other: 'Pozostałe',
    pageSize: 'Rozmiar papieru',
    pageOrientation: 'Orientacja',
    top: 'Góra',
    bottom: 'Dół',
    left: 'Lewo',
    right: 'Prawo',
    margins: 'Marginesy',
    fontSize: 'Rozmiar czcionki',
    goToBegin: 'Na początek',
    goToEnd: 'Na koniec',
    newTable: 'Nowa tabela',
    uploadYourFirstFile: 'Prześlij swój pierwszy plik',
    uploadFileInfo: 'Z łatwością prześlesz tutaj niektóre pliki za pomocą przycisku.',
    sorting: 'Sortowanie',
    gridView: 'Widok siatki',
    listView: 'Widok listy',
    nothingToPreview: 'Nie ma nic do podglądu.',
    myFiles: 'Moje pliki',
    deleted: 'Usunięte',
    access: 'Uprawnienia',
    absence: 'Brak',
    fileVersions: 'Wersje pliku',
    emptyFileVersions: 'Nie znaleziono wersji pliku.',
    collapsible: 'Zwijalna',
    selectBtn: 'Przycisk "Wybierz z listy"',
    openBtn: 'Przycisk "Otwórz"',
    addBtn: 'Przycisk "Dodaj"',
    attachments: 'Załączniki',
    attachedFIles: 'Powiązane pliki',
  },
  weekDays: {
    Mo: 'Pn',
    Tu: 'Wt',
    We: 'Śr',
    Th: 'Czw',
    Fr: 'Pt',
    Sa: 'Sb',
    Su: 'Nd',
  },
  operators: {
    '=': 'Równe',
    '!=': 'Nie równe',
    '>': 'Większe',
    '<': 'Mniejsze',
    '>=': 'Większe lub równe',
    '<=': 'Mniejsze lub równe',
    like: 'Zawiera',
    notLike: 'Nie zawiera',
    inList: 'Na liście',
    notInList: 'Nie na liście',
    eq: 'Równe',
    ne: 'Nie równe',
    gt: 'Większe',
    gte: 'Większe lub równe',
    lt: 'Mniejsze',
    lte: 'Mniejsze lub równe',
    iLike: 'Zawiera',
    notILike: 'Nie zawiera',
    filled: 'Wypełnione',
    notFilled: 'Nie wypełnione',
    between: 'Między',
  },
  reports: {
    salesOrderRegister: 'Rejestr zamówień',
    salesAnalize: 'Analiza sprzedaży',
    interactionAnalize: 'Analiza interakcji',
    interactionTimeAnalize: 'Analiza czasu pracy interakcji',
    inquiriesAnalize: 'Analiza zapytań ofertowych',
    email: 'Email',
  },
  table: {
    isStatic: 'Statyczny',
    counterpartyAccountingGroup: 'Grupa księgowa kontrahenta',
    productAccountingGroup: 'Grupa księgowa produktu',
    id: 'Id',
    acquisitionChannel: 'Żrudło pozyskania klienta',
    productionDate: 'Data produkcji',
    buyer: 'Nabywca',
    useDate: 'Uwzględnij daty',
    minValue: 'Minimalna wartość',
    maxValue: 'Maksymalna wartość',
    onlyQuantities: 'Tylko ilości do zamówienia',
    rangeA: 'Zakres A',
    rangeB: 'Zakres B',
    dateA: 'Data A',
    dateB: 'Data B',
    month: 'Miesiąc',
    uuid: 'UUID',
    positionNumber: 'Numer pozycji',
    industry: 'Branża',
    products: 'Produkty',
    numberStr: 'Numer (Str)',
    prefix: 'Prefiks',
    sort: 'Lp',
    noFilters: 'Brak filtrów',
    sms: 'Sms',
    internalReminder: 'Węwnętrzne',
    lead: 'Lead',
    sortNumber: 'N',
    sortNumberFull: 'Numer kolejny',
    ordered: 'Zamówiono',
    tasks: 'Zadania',
    comments: 'Komentarze',
    code: 'Kod',
    number: 'Numer',
    value: 'Wartość',
    name: 'Nazwa',
    dbName: 'Nazwa (BD)',
    fullName: 'Nazwa pełna',
    symbol: 'Symbol',
    dateOfBirth: 'Data urodzenia',
    sex: 'Płeć',
    male: 'Męska',
    female: 'Żeńska',
    article: 'Artykuł',
    supplierArticle: 'Artykuł dostawcy',
    minOrderValue: 'Minimalna wartość zamówienia',
    orderAdditionally: 'Dodatkowo zamówić',
    fullPackagingLength: 'Pełna długość/opakowanie',
    deliveryTime: 'Termin dostawy(dni)',
    type: 'Typ',
    markedToDelete: 'Zaznaczony do usunięcia',
    financialPeriod: 'Terminy finansowe',
    interaction: 'Interakcja',
    delete: 'Usuwanie',
    discription: 'Opis',
    processName: 'Nazwa procesu',
    minutesCarService: 'Czas obsługi samochodu',
    result: 'Wynik',
    customer: 'Klient',
    image: 'Obraz',
    reference: 'Referencja',
    referenceNumber: 'Nr referencji',
    numberOfWeighings: 'Ilość ważeń',
    receipts: 'Kwity',
    scaleReport: 'Raport wagowy',
    carPositionReport: 'Raport rozmieszczenia samochodu',
    scaleChangeReport: 'Raport zmianowy wagi',
    manual: 'ręcznie',
    createdAt: 'Utworzono',
    updatedAt: 'Data aktualizacji',
    deliveryDate: 'Data dostawy',
    shipmentDate: 'Data wysyłki',
    costs: 'Koszty',
    positionDescription: 'Opis pozycji',
    shipmentAddress: 'Adres wysyłki',
    whoPays: 'Kto płaci',
    documents: 'Dokumenty',
    shipmentType: 'Typ wysyłki',
    packageNumber: 'Numer paczki',
    role: 'Rola',
    web: 'www',
    facebook: 'Facebook',
    linkedin: 'LinkedIn',
    instagram: 'Instagram',
    status: 'Status',
    progressValue: 'Postęp',
    statusStr: 'Status (Str)',
    author: 'Autor',
    price: 'Cena',
    priceWithoutVAT: 'Cena Netto',
    sum: 'Kwota',
    bidSum: 'Kwota oferty',
    sumNetto: 'Kwota Netto',
    sumNettoTotalItems: 'Kwota Netto (total)',
    sumWithoutVAT: 'Kwota Netto',
    sumVAT: 'Kwota VAT',
    sumVat: 'Kwota VAT',
    sumWithVAT: 'Kwota Brutto',
    sumBrutto: 'Kwota Brutto',
    sumBruttoTotalItems: 'Kwota Brutto (total)',
    priceNational: 'Cena (kraj)',
    sumNetNational: 'Kwota Netto (kraj)',
    sumGrossNational: 'Kwota Brutto (kraj)',
    sumVATNational: 'Kwota VAT (kraj)',
    currency: 'Waluta',
    currencyStr: 'Waluta (Str)',
    note: 'Notatka',
    paymentTerms: 'Terminy płatności',
    trackTime: 'Czas razem za dane podejście',
    totalTime: 'Czas razem',
    time: 'Czas',
    hours: 'Godziny',
    minutes: 'Minuty',
    project: 'Projekt',
    deliveryMethod: 'Opcja wysyłki',
    packageMaterial: 'Materiał pakowania',
    comment: 'Uwagi',
    product: 'Produkt',
    description: 'Opis',
    decision: 'Decyzia',
    responsibility: 'Odpowiedzialność',
    jOM: 'J.M.',
    unitOfMeasure: 'J.M.',
    quantity: 'Ilość',
    discountPercent: '% zniżki',
    discountSum: 'Kwota zniżki',
    discount: 'Zniżka',
    fileSize: 'Rozmiar, kB',
    reports: 'Raporty',
    responsible: 'Odpowiedzialny',
    country: 'Kraj',
    email: 'E-mail',
    address: 'Adres',
    phone: 'Telefon',
    nameFull: 'Imię i nazwisko',
    deliverySchedule: 'Plan wysyłki',
    abbreviation: 'Skrót',
    actions: 'Akcje',
    addressesPhones: 'Adresy i telefony',
    priceCode: 'Kod cennika',
    contact: 'Kontakt',
    howToContact: 'Jak się skontaktować',
    productionOrder: 'Zlecenie produkcyjne',
    executor: 'Wykonawca',
    subExecutor: 'Podwykonawca',
    executionPeriod: 'Termin wykonania',
    changeDate: 'Data zmiany',
    user: 'Użytkownik',
    saleDate: 'Data sprzedaży',
    nip: 'NIP',
    lodging: 'kwatera',
    region: 'Rejon',
    pricesType: 'Rodzaj cen',
    customersGroup: 'Grupa klientów',
    language: 'Język',
    executorName: 'Nazwa wykonawcy',
    importance: 'Priorytet',
    executorRole: 'Rola wykonawcy',
    contents: 'Treść',
    template: 'Szablon',
    base: 'Podstawa',
    task: 'Zadanie',
    category: 'Kategoria',
    priority: 'Priorytet',
    postDate: 'Data wysyłania',
    topic: 'Temat',
    objectType: 'Rodzaj obiektu',
    objectDescription: 'Opis obiektu',
    objectId: 'ID obiektu',
    changeType: 'Rodzaj edycji',
    sent: 'Wysłano',
    checkReceivedSms: 'Sprawdzanie',
    receivedSms: 'Odebrane',
    smsId: 'SMS ID',
    constructor: 'Konstruktor',
    constr: 'Konstruktor',
    deleteConfirmation: 'Czy na pewno chcesz usunąć element z bazy danych?',
    manager: 'Handlowiec',
    constructionAddress: 'Adres budowy',
    correspondenceAddress: 'Adres korespondencyjny',
    commission: 'Prowizja, %',
    version: 'Wersja',
    orderNumber: 'Nr zamówienia',
    isActive: 'Aktywny',
    isService: 'Serwisowe',
    password: 'Hasło',
    resource: 'Zasób',
    isGeneral: 'Ogólne',
    isClosed: 'Zamknięte',
    baseDocument: 'Dokument podstawa',
    action: 'Akcja',
    emailAccount: 'Konto e-mail',
    sendingDate: 'Data wysłania',
    tags: 'Tagi',
    proforma: 'Pro-Forma',
    versionUuid: 'UUID wersji',
    state: 'Stan',
    date: 'Data',
    alreadyOrdered: 'Zamówiono',
    termsOfPayment: 'Termin zapłaty',
    executionTerm: 'Termin realizacji',
    order: 'Zlecenie',
    resultEmail: 'E-mail',
    days: 'Dni',
    alphaCode2: 'Alfa-2 kod',
    alphaCode3: 'Alfa-3 kod',
    pricelistKind: 'Rodzaj cennika',
    pricingMethod: 'Metoda wyceny',
    from: 'Od',
    to: 'Do',
    confirmed: 'Zweryfikowano',
    includedInMain: 'Zawarte w podstawowy',
    notUseDiscount: 'Nie stosuj rabatów',
    maxDiscount: 'Maksymalny rabat',
    priceType: 'Rodzaj ceny',
    beginDate: 'Data rozpoczęcia',
    startDate: 'Data początku',
    endDate: 'Data zakończenia',
    formula: 'Formula',
    belongs: 'Dotyczy',
    discountType: 'Rodzaj rabatu',
    default: 'Domyślny',
    customerAccess: 'Udostępnione dla mnie',
    documentType: 'Rodzaj dokumentu',
    storeFilesToHardDrive: 'Zapisz pliki na twardym dysku',
    filters: 'Filtry',
    dimensionX: 'Oś X',
    dimensionY: 'Oś Y',
    max: 'Max',
    min: 'Min',
    step: 'Krok',
    customerLockOrder: 'Blokuj zamówienie dla klienta',
    blockSelfServiceInterface: 'Blokuj interfejs samoobsługowy',
    blockUnblockSelfServiceInterface: 'Blokuj/Odblokuj interfejs samoobsługowy',
    agreement: 'Umowa',
    offer: 'Oferta',
    period: 'Okres',
    checkExecution: 'Sprawdź wykonanie',
    execution: 'Wykonanie',
    checker: 'Sprawdzający',
    hasLinks: 'Są powiązania',
    links: 'Powiązania',
    processed: 'Opracowane',
    icon: 'Ikona',
    title: 'Tytuł',
    parent: 'Należy do',
    parentDepartment: 'Należy do działu',
    viewType: 'Rodzaj widoku',
    readOnly: 'Tylko odczyt',
    component: 'Komponent',
    store: 'Repozytorium',
    model: 'Model',
    path: 'Scieżka',
    fieldTypePath: 'Ścieżka do typu',
    multiType: 'Typ złożony',
    detailPath: 'Scieżka do elementu edycji',
    view: 'Widok',
    schemeOfCargo: 'Relacja',
    counterparty: 'Kontrahent',
    counterparty2: 'Właściciel',
    typeOfOperation: 'Typ operacji',
    warehouse: 'Magazyn',
    ship: 'Statek',
    scale: 'Waga',
    scale2: 'Waga 2',
    scaleTwo: 'Waga 2',
    weighingStation: 'Stanowisko wagowe',
    brutto: 'Brutto',
    tare: 'Tara',
    netto: 'Netto',
    wagon: 'Wagon',
    loadCapacity: 'Nośność',
    deliveryOrder: 'Zlecenie',
    forwarder: 'Spedytor',
    doNotLoadToOracle: 'Nie ładuj do Oracle',
    expireDate: 'Data wygaśnięcia',
    closeDate: 'Data zamknięcia',
    docType: 'Typ',
    warehouseSection: 'Sektor magazynu',
    item: 'Towar',
    subject: 'Przedmiot',
    reason: 'Powód',
    declaredTonnage: 'Deklarowany tonaż',
    maxLoss: 'Maks. ubytek',
    numberAndDateSdk: 'Numer, data SDK',
    numberAndDateDSKAndMRN: 'Numer, data DSK/MRN',
    customGoodsStatus: 'Status celny towaru',
    carServiceTime: 'Czas obsługi samochodu',
    controlCompany: 'Firma kontrolna',
    correspondence: 'Korespondencja',
    descComment: 'Komentarz',
    internalComment: 'Komentarz wewnętrzny',
    disposition: 'Dyspozycja',
    dispositionForScale: 'Dyspozycja (na wagę)',
    productCard: 'Karta towaru',
    dispositionAndProductCard: 'Dyspozycja i karta towaru',
    deliveryNote: 'List przewozowy',
    trainNumber: 'Numer pociągu',
    containerNumber: 'Numer kontenera',
    recipient: 'Odbiorca',
    operationType: 'Typ operacji',
    vendor: 'Dostawca',
    contractor: 'Kontrahent',
    btsStatement: 'Oświadczenie BTS',
    station: 'Stanowisko',
    warehouseSquare: 'Magazyn/Plac',
    assortmentGmpPLus: 'Sortyment GMP+',
    closed: 'Zamknięte',
    startOfWeighting: 'Początek ważenia',
    endOfWeighting: 'Koniec ważenia',
    driver: 'Kierowca',
    carQueueStatus: 'Stan kolejki samochodów',
    carsQueueStatus: 'Stan kolejki samochodów',
    driverPhoneNumber: 'Numer telefonu kierowcy',
    phoneNumber: 'Nr. telefonu',
    carNumber: 'Numer samochodu',
    trailer: 'Naczepa',
    idNumber: 'Numer dowodu',
    carrier: 'Przewoźnik',
    parkingAriveDateAndTime: 'Data i godzina przyjazdu na parking',
    retaring: 'Retarowanie',
    done: 'Wykonane',
    multiple: 'Wielokrotność',
    allowRetaring: 'Pozwól na retarowanie',
    allowSetZero: 'Pozwól na zerowanie kwitu',
    assortment: 'Sortyment',
    tests: 'Badania',
    dateOfTest: 'Data badania',
    arriveFrom: 'Przyjazd od',
    lockArrivesFrom: 'Blokowanie przyjazdu od',
    cancelWeighting: 'Anuluj ważenie',
    position: 'Pozycja',
    scaleBrutto: 'Waga brutto',
    scaleTare: 'Waga tary',
    scaleNetto: 'Waga netto',
    balancedScale: 'Waga stabilna',
    unbalancedScale: 'Waga niestabilna',
    bruttoTime: 'Data wagi brutto',
    tareTime: 'Data wagi tary',
    nettoTime: 'Data wagi netto',
    bruttoAuthor: 'Autor brutto',
    tareAuthor: 'Autor tary',
    nettoAuthor: 'Autor netto',
    approved: 'Przyjęty',
    approvedDate: 'Data przyjęcia',
    approvedAuthor: 'Autor przyjęcia',
    carriageNumber: 'Numer wagonu',
    notUse: 'Nie używaj',
    useActualWarehouse: 'Użyj rzeczywistego magazynu',
    sendDriversSms: 'Wyślij sms kierowcom',
    useProductCondition: 'Użyj stanu produktu',
    numberOfCopies: 'Liczba kopii',
    returnToWarehouse: 'Powrót do magazynu',
    disableControlOnScales: 'Wyłącz kontrole na wagach',
    reverseOperation: 'Odwrotna operacja',
    disableControlInDispositions: 'Wyłącz kontrole na dyspozycjach',
    actualWarehouse: 'Miejsce założenia',
    deliveryNoteNumber: 'Numer listu przewozowego',
    useStability: 'Użyj stabilność',
    scalesNumber: 'Numer wagi',
    scalesVersion: 'Wersja wagi',
    updatePeriod: 'Okres odnowienia',
    iotGateParameters: 'Parametry bramy Iot',
    lightTable: 'Tablica świetlna',
    additional: 'Dodatkowy',
    mine: 'Kopalnia',
    advicesNumber: 'Numer awiza / składu',
    dispositionAuthor: 'Dyspozycję wystawił',
    dispositionCon: 'Dyspozycję',
    orderAuthor: 'Zlecenie wystawił',
    paramValues: 'Znaczenia parametrów',
    queryParam: 'Parametr Query (?)',
    hashParam: 'Parametr Hash (#)',
    typeOfDocument: 'Typ dokumentu',
    arrivalDay: 'Data przyjazdu na parking',
    arrivalDateDriver: 'Data i godzina przyjazdu na parking',
    vehicle: 'Numer samochodu',
    vehicleWagon: 'Numer wagonu',
    key: 'Klucz',
    read: 'Podgląd',
    modify: 'Edycja',
    factor: 'Współczynnik',
    deviation: 'Odchylenie',
    added: 'Dołączony',
    color: 'Kolor',
    accessRole: 'Rola dostępu',
    weightingType: 'Typ ważenia',
    firstWeighting: 'Pierwsze ważenie',
    secondWeighting: 'Drugie ważenie',
    barCode: 'Kod kreskowy',
    driverTicket: 'Bilet kierowcy',
    entryTicket: 'Bilet wjazdowy',
    defaultWarehouse: 'Magazyn podstawowy',
    externalCode: 'Kod zewnętrzny',
    carBrand: 'Marka samochodu',
    declaredTare: 'Tara deklarowana',
    idCard: 'Dowód osobisty',
    attentionCount: 'Liczba uwag',
    isCardNumber: 'Numer dowodu',
    isCardSerial: 'Seria dowodu',
    termsOfUse: 'Zgoda na warunki użytkownika',
    desktopMode: 'Tryb pulpitu (AMP)',
    desktopName: 'Nazwa pulpitu (AMP)',
    printerName: 'Nazwa drukarki',
    printerList: 'Lista drukarek',
    selectProtocol: 'Wybór protokołu',
    server: 'Serwer',
    port: 'Port',
    api: 'Api',
    reclamationRelatedTasks: 'Zadania powiązane z reklamacją',
    sendCommand: 'Wyślij polecenie',
    typeParse: 'Typ parsowania',
    registry: 'Rejestr',
    comPort: 'Port COM',
    guarantee: 'Gwarancja',
    initDate: 'Data początkowa',
    capasity: 'Pojemność',
    carsBuffer: 'Bufor samochodu',
    carsBufferTerminal: 'Bufor samochodów na terminalu',
    carsBufferParking: 'Bufor samochodów na parkingu',
    externalId: 'Identyfikator zewnętrzny',
    externalIdShort: 'Zewnętrzne id',
    queueOn: 'W kolejce',
    reflect: 'Odzwierciedlić',
    notes: 'Notatka',
    scalesType: 'Typ wagi',
    automaticWeightingEnd: 'Automatyczne zakończenie ważenia',
    barCodePrefix: 'Prefiks kodu kreskowego',
    handlers: 'Opracowania',
    scaleProtocol: 'Protokół wagi',
    videoRecorder: 'Rejestrator wideo',
    usingVideoRecorder: 'Wykorzystanie rejestratora wideo',
    inquiry: 'Zapytanie',
    ptotocols: 'Protokoły',
    protocol: 'Protokół',
    numberCopies: 'Ilość kopii',
    productionQuantity: 'Ilość produkcyjna',
    orientation: 'Orientacja',
    size: 'Rozmiar',
    rollPrinter: 'Drukarka rolkowa',
    clientId: 'Identyfikator klienta',
    computerName: 'Nazwa komputera',
    networkPort: 'Port sieciowy',
    prohibitionWorkSeveralSessions: 'Zakaz pracy w kliku sesjach',
    issuanceRailwayInstructions: 'Wystawienie dyspozycji kolejowych',
    predefinedName: 'Nazwa zdefiniowana',
    supplier: 'Dostawca',
    versioningMethod: 'Metoda wersjonowania',
    main: 'Główna',
    postCode: 'Kod pocztowy',
    constructionPostCode: 'Kod poczt. budowy',
    city: 'Miejscowość',
    adminAccess: 'Dostęp admina',
    userAccess: 'Dostęp użytkownika',
    statusgroup: 'Grupa statusów',
    code1C: 'Kod 1C',
    sortId: 'Kolejność',
    placing: 'Rozmieszczenie',
    paymentType: 'Rodzaj płatności',
    cashFlowItem: 'El. przepływu środków pieniężnych',
    sumPayment: 'Kwota zapłaty',
    typeOfMovement: 'Rodzaj ruchu',
    typeOfPayment: 'Typ płatności',
    startTime: 'Czas rozpoczęcia',
    endTime: 'Koniec czasu',
    leader: 'Kierownik',
    deputy: 'Zastępca',
    employee: 'Pracownik',
    useRestriction: 'Użyj ograniczenia ogólne',
    useDepartmentRestriction: 'Użyj ograniczenia wg działów',
    calendarEvent: 'Kalendarz Wydarzenie',
    valueType: 'Typ wartości',
    field: 'Pole',
    searchField: 'Pole do wyszukiwania',
    operator: 'Warunek',
    moving: 'Przesunięcie',
    yearNumber: 'Rok',
    lastNumber: 'Ostatni numer',
    singularName: 'Nazwa pojedynczego',
    singularTitle: 'Tytuł pojedynczego',
    lang: 'Tłumaczenie',
    beginWeighting: 'Początek ważenia',
    endWeighting: 'Koniec ważenia',
    nettoOverAll: 'Netto ogólne, T',
    nettoRegistered: 'Netto rejestrowane, T',
    enumerator: 'Licznik',
    appObject: 'Obiekt systemu',
    object: 'Obiekt',
    incomingDate: 'Data dokumentu',
    incomingNumber: 'Numer dokumentu zewnętrznego',
    client: 'Klient',
    contactPerson: 'Osoba kontaktowa',
    basicDocument: 'Podstawowy dokument',
    salesOrder: 'Zamówienie klienta',
    exchangeRate: 'Kurs wymiany',
    exchangeRateDate: 'Data kursu walutowego',
    exchangeRateTableNumber: 'Numer tabeli kursów walut',
    amountsType: 'Typ kwoty',
    warehouseFrom: 'Magazyn od',
    warehouseTo: 'Magazyn do',
    signature: 'Podpis',
    hidden: 'Ukryte',
    hiddenM: 'Ukryty',
    taxRate: 'Wysokość podatku',
    basicItem: 'Element podstawowy',
    forServices: 'Dla usług',
    reverseChange: 'Zmiana odwrotna',
    vatAmount: 'Kwota kaucji',
    netWeight: 'Waga netto',
    bruttoWeight: 'Waga Brutto',
    vatRate: 'Stawka VAT',
    paymentMethod: 'Sposób zapłaty',
    paymentDate: 'Termin płatności',
    visible: 'Widoczne',
    use: 'Użyj',
    width: 'Szerokość',
    imo: 'IMO',
    length: 'Długość',
    trackModel: 'Model transportu',
    daysSinceAdmission: 'Dni od przyjęcia',
    birthDate: 'Data urodzenia',
    fieldType: 'Typ pola',
    dataType: 'Typ danych',
    averagePrice: 'Średnia cena',
    grossAmount: 'Wartość (brutto)',
    lineNumber: 'L. p.',
    reclamationNumber: 'Nr reklamacji',
    clientName: 'Imię klienta',
    clientSurname: 'Nazwisko klienta',
    telephone: 'Telefon',
    deliveryStatus: 'Status przesyłki',
    amount: 'Wartość',
    deliveryAddress: 'Adres dostawy',
    salesDate: 'Data sprzedaży',
    bankAccount: 'Numer konta',
    begin: 'Początek',
    ending: 'Koniec',
    stop: 'Stop',
    entry: 'Data wjazdu',
    plannedEntry: 'Planowana data wjazdu',
    route: 'Trasa',
    counterpartyAddress: 'Adres kontrahenta',
    realizationDate: 'Data realizacji',
    maxLength: 'Długość maksymalna',
    disabled: 'Tylko odczyt',
    login: 'Login',
    passwordConfirmation: 'Potwierdzenie hasła',
    fullRights: 'Pełne uprawnienia',
    use2FA: 'Uwierzytelnianie dwuskładnikowe',
    externalUser: 'Zewnętrzny użytkownik',
    useCustomerAccess: 'Użyj ograniczenie dostępu wg klientów',
    useForExternalDrivers: 'Kara dla zewnętrznego kierowcy',
    useForInternalDrivers: 'Kara dla wewnętrznego kierowcy',
    shortName: 'Skrócona nazwa',
    accountingName: 'Nazwa do księgowości',
    isPaid: 'Opłacony',
    isCanceled: 'Anulowany',
    isHandcrafted: 'Ręcznie robione',
    isPrint: 'Wydrukowany',
    isSelect: 'Wybrany',
    punishments: 'Opłaty za korzystanie z parkingu',
    punishment: 'Naliczona opłata',
    punishmentType: 'Rodzaj opłat za korzystanie z parkingu',
    periodType: 'Typ okresu',
    showYTotal: 'Podsumowania pionowe',
    showXTotal: 'Podsumowania poziome',
    ref: 'Odnośnik',
    payment: 'Dokument zapaty',
    daysExceeded: 'Przekroczono dni',
    ip: 'IP',
    method: 'Metod',
    url: 'URL',
    query: 'Zapytanie',
    params: 'Parametr',
    body: 'Ciało',
    headers: 'Nagłówki',
    waitingTime: 'Czas oczekiwania',
    payedAdvance: 'Wpłacona zaliczka',
    skonto: 'Skonto',
    sortBy: 'Sortuj wg',
    fakturaCheck: 'Faktura',
    fakturaName: 'Nazwa',
    fakturaNIP: 'NIP',
    street: 'Ulica',
    locality: 'Miejscowość',
    multi: 'Wiele',
    executionDays: 'Dni na wykonanie',
    executionHours: 'Godziny na wykonanie',
    executionMinutes: 'Minut na wykonanie',
    currentDateExecution: 'Wykonaj w jednym dniu',
    script: 'Scenariusz',
    trafficLight: 'Sygnalizacja świetlna',
    useTrafficLight: 'Użyj sygnalizacja świetlna',
    connection: 'Połączenie',
    okTrafficLight: 'Ok',
    plusTrafficLight: 'Za daleko',
    minusTrafficLight: 'Za blisko',
    typeTrafficLightSignal: 'Rodzaj sygnału sterującego',
    controlSignalLight1: '1 jako sygnał sterujący',
    controlSignalLight0: '0 jako sygnał sterujący',
    euMember: 'Członek UE',
    allowChangeExecutor: 'Pozwolić na zmianę wykonawca',
    allowChangeStage: 'Pozwolić na zmianę etapu',
    requiredComment: 'Wymagany komentarz',
    stage: 'Etap',
    departureDate: 'Data wyjazdu',
    vehicleType: 'Rodzaj samochodu',
    transportWay: 'Rodzaj transportu',
    onlySelected: 'Tylko wybrane',
    rate: 'Kurs',
    multiplier: 'Współczynnik',
    rateDate: 'Data kursu',
    activeDate: 'Data wpływu',
    table: 'Tabela dla kursów wymian walut',
    tableNumber: 'Numer tabeli NBP',
    expression: 'Wyrażenie',
    sortDirection: 'Kierunek sortowania',
    report: 'Raport',
    attribs: 'Atrybuty',
    systemRowNumber: 'Numer wiersza (systemowy)',
    detailView: 'Widok elementu',
    reportR27: 'Raport R27',
    shift: 'Zmiana',
    presentation: 'Prezentacja',
    variant: 'Wariant',
    tooltip: 'Podpowiedź',
    forwarders: 'Spedytorzy',
    isTotal: 'Pokaż razem',
    regon: 'REGON',
    rowNumber: 'Nr wiersza',
    krs: 'KRS',
    payerVat: 'Podatnik VAT',
    payerVies: 'Podatnik VAT-UE',
    isStarted: 'Jest rozpoczęty',
    message: 'Komunikat',
    documentationForm: 'Dokumentacja księgowa',
    legalForm: 'Forma prawna',
    possitionWh: 'Pozycja WH',
    items: 'Elementy',
    itemsReady: 'Elementy gotowe do odbioru',
    cost: 'Koszt',
    orders: 'Zamówenia',
    serviceOrders: 'Zlecenia serwisowe',
    selectedOrders: 'Wybrane zamówenia',
    supplierReclamations: 'Reklamacje do dostawcy',
    reclamationOrderNumber: 'Numer zlecenia',
    showBusyTime: 'Pokaż zajęty czas',
    taxation: 'Opodatkowanie',
    orderForTransport: 'Zamówienie na transport',
    activitiesDescription: 'Opis działalności',
    hideDetails: 'Ukryj szczegóły',
    balance: 'Pozostałość',
    forShipment: 'Do wysyłki',
    allowNav: 'Dostępne szczegóły',
    hold: 'Ładownia',
    shiftOrders: 'Zlecenia zmianowe',
    dateFrom: 'Obowiązuje od',
    dateTo: 'Obowiązuje do',
    workShifts: 'Zmiana',
    filter: 'Filtr',
    arrivalDate: 'Data przybycia',
    loadingStart: 'Rozpoczęcie ładowania',
    loadingEnd: 'Koniec ładowania',
    dockNumber: 'Numer doku',
    destinationPort: 'Port docelowy',
    plannedDockingTime: 'Planowany czas dokowania',
    arrivalDraft: 'Arrival draft',
    departureDraft: 'Departure draft',
    afterLoadingDraft: 'After loading draft',
    forwardDraft: 'Forward draft',
    aftDraft: 'Aft draft',
    forwardDraftDeparture: 'Forward draft departure',
    aftDraftDeparture: 'Aft draft departure',
    closingDate: 'Data zamknięcia',
    cargoNumber: 'Numer ładunku',
    captain: 'Kapitan',
    destination: 'Miejsce docelowe',
    sender: 'Nadawca',
    agent: 'Agent',
    serviceChargesServices: 'Podstawowe usługi',
    serviceAgreementsServices: 'Podstawowe usługi',
    shipRegistration: 'Rejestracja statku',
    service: 'Usługa',
    material: 'Materiał',
    pricelist: 'Cena',
    storagePeriod: 'Okres przechowywania',
    holds: 'Ładownia',
    counterparties: 'Kontrahenci',
    recipients: 'Odbiorcy',
    mail: 'Poczta',
    notificationType: 'Typ powiadomienia',
    work: 'Wykonywane prace',
    equipment: 'Sprzęt',
    workQuantity: 'Ilość pracy',
    hoursQuantity: 'Ilość godzin',
    workDateFrom: 'Początek pracy',
    workDateTo: 'Koniec pracy',
    shipped: 'Wysłany',
    batches: 'Partie',
    exporters: 'Eksporterzy',
    exporter: 'Eksporter',
    shipLoading: 'Ładowanie statku',
    totalAll: 'Ogółem',
    breaks: 'Przerwy opadowe',
    foreman: 'Brygadzista',
    warehouseShipPrint: 'Podział ładunku na magazyny/place',
    equipmentShipPrint: 'Numery dżwigów',
    shipRegistrationPrint: 'Raport przeładunkowy',
    holdsPrint: 'Ładownie / holds',
    algorithm: 'Algorytm',
    hour: 'Godz.',
    shipIn: 'Wszedł',
    shipOut: 'Wyszedł',
    shipStart: 'Rozpoczął',
    shipStop: 'Zakończył',
    totalEn: 'Total',
    confirmation: 'Zatwierdzenie',
    remained: 'Pozostało',
    remainedEn: 'Remained',
    tonnage: 'Tonaż',
    workingPlace: 'Miejsce pracy',
    function: 'Funkcja',
    equipmentNumber: 'Nr urządzenia',
    itemNumber: 'Poz.',
    orderNum: 'Zlec.',
    shemeOfCargoNum: 'Rel.',
    transportQuantity: 'Ilość śr. trans.',
    akord: 'Akord',
    daily: 'Dniówka',
    totalHours: 'Razem godz.',
    worksheet: 'Karta pracy',
    workersQuantity: 'Ilość prac.',
    invoiceItems: 'Pozycje faktury',
    summary: 'Podsumowanie',
    batch: 'Partia',
    chargedServices: 'Naliczone usługi',
    numberOfDays: 'Liczba dni',
    mainToolbar: 'Podstawowy toolbar',
    height: 'Wysokość',
    depth: 'Głębokość',
    grossWeight: 'Waga brutto',
    volume: 'Objętość',
    dimensions: 'Wymiary',
    weightAndVolume: 'Waga i objętość',
    owner: 'Właściciel',
    basic: 'Podstawowy',
    panelMode: 'Tryb panelu',
    inventoryBatches: 'Partie zapasów',
    box: 'Komórka magazynowa',
    boxTo: 'Komórka magazynowa do',
    header: 'Nagłówek',
    periodicity: 'Częstotliwość',
    numberFormat: 'Format numeru',
    monthDay: 'Dzień miesiąca',
    monthNumber: 'Numer miesiąca',
    quarterNumber: 'Numer kwartału',
    yearTwoDigits: 'Rok (2 znaki)',
    yearFourDigits: 'Rok (4 znaki)',
    organization: 'Organizacja',
    CounterpartyCode: 'Kod kontrahenta',
    storeCode: 'Kod magazynu',
    separator: 'Separator',
    markDeletedMails: 'Synchronizacja usuniętych',
    ticketNumber: 'Nr biletu',
    researchResult: 'Wyniki badań',
    worker: 'Opracowanie',
    mode: 'Tryb',
    isDefault: 'Domyślny',
    productVariant: 'Wariant produktu',
    positions: 'Pozycje',
    additionalProducts: 'Dodatkowe produkty',
    reservations: 'Rezerwacje',
    taras: 'Tara',
    wastes: 'Odpady',
    text: 'Tekst',
    operation: 'Operacja',
    plusAnswers: 'Odpowiedzi Plusa',
    loggingOn: 'Włącz logowanie',
    forReception: 'Magazyn dla przyjęcia',
    weight: 'Ilość',
    fullData: 'Pełne dane',
    labelPosition: 'Rozmieszczenie tytułu',
    defaultText: 'Domyślny tekst',
    dataLinked: 'Powiązany ze zmienną',
    variable: 'Zmienna',
    placeholder: 'Podpowiedź',
    dateType: 'Typ daty',
    externalData: 'Zewnętrzne źródło danych',
    externalUsername: 'Użytkownik bazy danych ',
    externalPassword: 'Hasło',
    externalServer: 'Serwer',
    externalPort: 'Port',
    externalDatabase: 'Baza danych',
    externalDialect: 'Dialekt',
    tag: 'Tag',
    groupChange: 'Zmiana grupowa',
    externalDateKind: 'Rodzaj daty',
    externalDateFormat: 'Format daty',
    recorder: 'Rejestrator',
    settlementDocument: 'Uslugi naliczone',
    typeOfCounterparty: 'Typ kontrahenta',
    typeOfLocation: 'Typ lokalizacji',
    numberPrecision: 'Długość',
    numberScale: 'Dokładność',
    primaryKey: 'Główny klucz',
    autoIncrement: 'Przyrost automatyczny',
    index: 'Indeksowane',
    unique: 'Unikalne',
    allowNull: 'Zezwalaj na Null',
    notNull: 'Nie NULL',
    defaultValue: 'Domyślnie znaczenie',
    fullSearch: 'Pełnotekstowe wyszukiwanie',
    dynamicModel: 'Dynamiczny model',
    useTranslate: 'Użyj tłumaczenia',
    tableName: 'Nazwa tabeli',
    accountingArea: 'Obszar księgowy',
    characteristic: 'Charakterystyka',
    warehouseAvailability: 'Stan magazynowy',
    booked: 'Zarezerwowano',
    bookedHere: 'Zarezerwowano tu',
    productionStock: 'Stan mag. prod.',
    minimumStock: 'Zapas minimalny',
    minA: 'Min A',
    orderInsufficientGoods: 'Zamówione towary niedostateczne',
    stockLevelMinA: 'Stan magazynowy po minA',
    goodsInsufficientFree: 'Towary niedostateczne (wolne)',
    amountToOrder: 'Ilość do zamówienia',
    quantityToReserve: 'Ilość do rezerw.',
    stockLevelAsNeeded: 'Stan mag. do zapotrz.',
    series: 'Seria',
    wagonType: 'Typ wagonu',
    firm: 'Firma',
    executionTermRecl: 'Realizacja',
    goodsCosts: 'Koszty towarów',
    active: 'Aktywne',
    setSchedule: 'Ustaw harmonogram',
    daysOfWeek: 'Dni tygodnia',
    months: 'Miesiący',
    daysOfMonth: 'Dni miesiąca',
    download: 'Pobieranie',
    folder: 'Katalog',
    buisnessProcess: 'Proces biznesowy',
    groupType: 'Rodzaj grupy',
    rowsCount: 'Liczba wierszy',
    maxRows: 'Maksymalna liczba wierszy',
    toggleGroup: 'Przełącza grupę',
    hyperLink: 'Hiperłącze',
    acceptFiles: 'Dostępne pliki',
    browseText: 'Tekst wyboru',
    bpStageEditing: 'Edycja etapu procesu biznesowego',
    stageDescription: 'Opis etapu',
    formTypeToShow: 'Typ formularza do pokazania',
    isFinal: 'Finał',
    stepManuallSelect: 'Ręczny wybór następnego etapu',
    nextStep: 'Następny etap',
    link: 'Link',
    functions: 'Funkcje',
    editCondition: 'Edycja warunku',
    showTaskDetails: 'Pokaż szczegóły zadania',
    сheckExecution: 'Sprawdź wykonanie',
    checkRielaProducts: 'Sprawdź producty Riely',
    constructionCity: 'Miejscowość budowy',
  },
  report: {
    groupLvls: 'Poziomy grupowań',
    variants: 'Warianty raportu',
    writeVariant: 'Zapisz wariant',
    onlyForThisUser: 'Tylko dla tego użytkownika',
    newVariant: 'Nowy wariant raportu',
    changeVariantName: 'Zmień nazwę wariantu raportu',
    deleteVariant: 'Usunąć wariant raportu',
    selectedFields: 'Wybrane pola',
    columnName: 'Nazwa kolumny',
    filtered: 'filtrowalne',
    grouped: 'grupowalne',
    functional: 'funkcjonalne',
    calculated: 'wyliczane',
    byDate: 'Na dzień',
    byPeriod: 'Za okres',
    byPeriodFrom: 'Za okres: od ',
    byPeriodTo: ' do ',
    functions: {
      sum: 'Suma',
      min: 'Min',
      max: 'Maks',
      average: 'Średnie',
    },
    total: 'Razem',
    isTotal: 'Podsumowujące',
    filters: 'Filtry',
    groups: 'Grupowanie',
    resourses: 'Sumowanie',
    sorts: 'Sortowanie',
    funcs: 'Wyliczenia',
    calcs: 'Wyliczenia',
    msg: {
      periodControl: 'Początek okresu nie może być większy od końca.',
      generateError: 'Błąd wygenerowania raportu',
    },
    notGenerated: 'Raportu nie wygenerowano. Naciśnij "Generuj", aby wygenerować raport',
    hideDetails: 'Ukryj dane detaliczne dla grupowań',
    withParentGroup: 'Na jednym poziomie z grupowaniem nadrzędnym',
  },
  project: {
    completed: 'Zakończony',
    inProgress: 'W trakcie',
    behind: 'Opóźniony',
    taskOverview: 'Podgląd zadań',
    projectStatus: 'Status projektu',
    weeklyReport: 'Raport tygodniowy',
    monthlyReport: 'Raport miesięczny',
    action: 'Akcja',
    settings: 'Ustawienia',
    tasks: 'Zadania',
    recentActivity: 'Ostatnia aktywność',
    completedTasksOf: 'Zadań wykonanych z',
    status: 'Status',
    assignedTo: 'Przypisany do',
    totalTimeSpent: 'Całkowity przypisany czas',
  },
  email: {
    message: 'Wiadomości',
    email: 'Email',
    noTitle: '<Brak tematu>',
    otherFiles: 'Pozostałe pliki',
    imapHost: 'Serwer IMAP',
    smtpHost: 'Server SMTP',
    imapPort: 'Port IMAP',
    smtpPort: 'Port SMTP',
    imapTls: 'Użyj SSL/TLS',
    smtpTls: 'Użyj SSL/TLS',
    forReceive: 'Dla odbioru',
    emailSended: 'Email wysłany',
    forSend: 'Dla wysyłki',
    storeReceived: 'Zapisz odebrane',
    storeSended: 'Zapisz wysłane',
    attachments: 'Załączniki',
    newMessage: 'Nowa wiadomość',
    other: 'Pozostałe',
    inbox: 'Odebrane',
    invitedBy: 'zaprosił Cię',
    youOrganizer: 'Jesteś organizatorem',
    recievedEmail: 'Odebrany e-mail: ',
    productionFile: 'Plik produkcyjny',
    signed: 'Podpisane',
    signedByClient: 'Podpisane przez klienta',
    userSignature: 'Podpis użytkownika',
    sentEmail: 'Wysłany e-mail: ',
    sent: 'Wysłane',
    toSend: 'Do wysłania',
    drafts: 'Wersje robocze',
    spam: 'Spam',
    trash: 'Kosz',
    title: 'Wiadomość e-mail',
    writeSomething: 'Wpisz treść...',
    contents: 'Treść',
    from: 'Od',
    to: 'Do',
    cc: 'DW',
    bcc: 'UDW',
    subject: 'Temat',
    subjectPh: 'Temat wiadomości',
    body: 'Wiadomość',
    bodyPh: 'Treść wiadomości..',
    signatures: 'Podpisy',
    response: 'Odpowiedz',
    responseAll: 'Odpowiedz wszystkim',
    onLineMode: 'Tryb on-line',
    msg: {
      selectEmailFirst: 'Nie wybrano żadnej wiadomości e-mail!',
    },
    all: 'Wszystkie',
    none: 'Żadne',
    seen: 'Przeczytane',
    unseen: 'Nieprzeczytane',
    withLinks: 'Z powiązaniami',
    withoutLinks: 'Bez powiązań',
    processed: 'Opracowane',
    notProcessed: 'Nie opracowane',
    setAs: 'Oznacz jako',
    create: {
      newInteraction: 'Nowa interakcja',
      newReclamation: 'Nowa reklamacja',
      newCustomerRequest: 'Nowe zapytanie ofertowe',
      customerRequestCorrection: 'Korekta zapytania ofertowego',
    },
    attachTo: {
      interaction: 'Interakcja',
      reclamation: 'Reklamacja',
      customerRequest: 'Zapytanie ofertowe',
    },
    noEmails: 'Brak e-maili',
    shown: 'Wyświetlono',
    labels: 'Etykiety',
    moveToJunk: 'Przenieś do spamu',
    moveToTrash: 'Przenieś do kosza',
    moveTo: 'Przenieś do ...',
    setLabel: 'Oznacz etykietą',
    manageLabels: 'Zarządzaj etykietami',
    setMailSignature: 'Ustaw własny podpis',
    manageTemplates: 'Zarządzaj szablonami',
    selectEmailAccount: 'Wybierz konto e-mail',
    emailMoved: 'Zaznaczone wiadomości zostały przeniesione!',
    fillCounterparties: 'Wypełnij kontrahentów / osoby kontaktowe w e-mail',
    fillCounterparty: 'Wypełnij kontrahenta / osobę kontaktową',
    defaultEmailAccount: 'Domyślne konto email',
    fillConterpartiesBegin: 'System zaczął wypełniać kontrahentów (osób kontaktowych) do e-mail',
    fillConterpartiesEnd: 'System skończył wypełniać kontrahentów (osób kontaktowych) do e-mail',
  },
  configurator: {
    selectedParameters: 'Wybrane parametry',
    byNumber: 'wg nr...',
    byName: 'wg nazwy / opisu ...',
  },
  settings: {
    title: 'Ustawienia',
    lsbThemeDefault: 'Default',
    lsbThemeLight: 'Jasny',
    lsbThemeDark: 'Ciemny',
    lsbTypeFixed: 'Pełny',
    lsbTypeCondensed: 'Zwinięty',
    lsbTypeScrollable: 'Przewijalny',
  },
  businessProcess: {
    definitionsListTitle: 'Procesy biznesowe',
    definitionsFormTitle: 'Edycja schematu procesu',
    fillExecutorOrExecutorRole: 'Proszę wypełnić rolę executora lub executora',
    noExecutor: 'Nie ma wykonawcy.',
  },
  editTypes: {
    created: 'Dodany',
    edited: 'Zmieniony',
    markedToDeleteRestore: 'Zaznaczony do usunięcia / Przywrócony',
    deleted: 'Usunięty',
  },
  tagsView: {
    refresh: 'Odśwież',
    close: 'Zamknij',
    closeOthers: 'Zamknij Inne',
    closeAll: 'Zamknij Wszystkie',
  },
  navigation: {
    newSubsystem: 'Nowy podsystem',
    editSubsystem: 'Edycja podsystemu',
    newPartition: 'Nowy rozdział',
    newRoute: 'Nowy widok',
    editRoute: 'Edytuj widok',
    getPrezentation: 'Pobierz prezentację z repozytorium',
  },
  documentation: {},
  permission: {},
  guide: {},
  components: {
    historyOfChanges: 'Historia zmian',
    versionDetails: 'Szczegóły wersji',
    versionsCompare: 'Porównanie wersji',
    relatedTasks: 'Powiązane zadania.',
    linkedDocs: 'Powiązane dokumenty',
    subordination: 'Struktura podporządkowania',
    advancedSearch: 'Wyszukiwanie zaawansowane',
    viewSettings: 'Ustawienia widoku',
    addReminderView: 'Dodaj przypomnienie',
    periodManager: 'Ustawienie okresu',
    report: 'Raport',
    searchByTicketNumber: 'Szukaj po numerze bileta',
  },
  example: {},
  errorLog: {
    qrCodeNotFound: 'Qr kodu nie znaleziono',
  },
  excel: {},
  zip: {},
  pdf: {},
  ships: {
    imo: 'Międzynarodowa organizacja morska',
  },
  theme: {
    leftSidebar: 'Lewy pasek',
  },
  fm: {
    components: {
      fields: {
        input: 'Wprowadzenie',
        button: 'Przycisk',
        textarea: 'Pole tekstowe',
        number: 'Numer',
        radio: 'Radio',
        checkbox: 'Checkbox',
        time: 'Czas',
        date: 'Data',
        rate: 'Rate',
        color: 'Kolor',
        select: 'Pole wyboru',
        switch: 'Przełącz',
        slider: 'Suwak',
        text: 'Tekst',
        blank: 'Custom',
        fileupload: 'Wgraj Plik',
        imgupload: 'Wgraj Obraz',
        editor: 'Edytor',
        cascader: 'Cascader',
        table: 'Tabela podporządkowana',
        'view-list': 'Lista',
        pagination: 'Paginacja',
        grid: 'Siatka',
        card: 'Karta',
        tabs: 'Zakładki',
        group: 'Grupa',
        divider: 'Divider',
        toolbar: 'Pasek Narzędzi',
        container: 'Container',
        btnGroup: 'Grupa Przycisków',
        collapse: 'Zwiń',
        dropdown: 'Upuść',
        inputGroup: 'Wprowadź Grupę',
        dynamic: 'Dynamiczny',
        dynamicList: 'Lista dynamiczna',
        link: 'Link',
        img: 'Obrazek',
        file: 'Plik',
        _list: 'Lista',
      },
      basic: {
        title: 'Komponent Podstawowy',
      },
      advance: {
        title: 'Komponent Zaawansowany',
      },
      layout: {
        title: 'Rozmieszczenie',
      },
    },
    description: {
      containerEmpty: 'Możesz przeciągnąć i upuścić pozycję z lewej by dodać do Komponentów',
      configEmpty: 'Proszę dodać Pozycję',
      tableEmpty: 'Możesz przeciągnąć i upuścić pozycję z lewej by dodać do Komponentów',
      uploadJsonInfo: 'Istnieje format JSON poniżej, możesz go nadpisać swoim kodem JSON. ',
    },
    message: {
      copySuccess: 'Kopiowanie zakończone',
      validError: 'Uwierzytelnianie formularza nie powiodło się',
      restoreItem: 'Element zostanie przywrócony!',
      deleteItem: 'Element zostanie zaznaczony do usunięcia!',
      topicEmpty: 'Temat nie może być pusty!!',
      reportOptionGetError: 'Błąd otrzymania ustawień raportu',
      recipientEmpty: 'Nie wskazano odbiorcy!!',
    },
    actions: {
      import: 'Import JSON',
      clear: 'Wyczyść',
      preview: 'Podgląd',
      json: 'Generuj JSON',
      code: 'Generuj Kod',
      getData: 'Get Data',
      reset: 'Reset',
      copyData: 'Kopiuj Dane',
      cancel: 'Anuluj',
      confirm: 'Potwierdź',
      addOption: 'Dodaj Opcję',
      addColumn: 'Dodaj kolumnę',
      addItem: 'Dodaj pozycję',
      addTab: 'Dodaj Pole',
      upload: 'Wgraj',
      add: 'Dodaj',
      addCorrespondence: 'Dodaj korespondencję',
    },
    config: {
      form: {
        title: 'Formularz',
        actions: 'Działania',
        functionNaem: 'Nazwa funkcji',
        functionDescription: 'Opis funkcji',
        dataSource: 'Żródło danych',
        labelPosition: {
          title: 'Nazwa',
          left: 'Lewy',
          right: 'Prawy',
          top: 'Góra',
        },
        labelWidth: 'Szerokość Etykiety',
        size: 'Rozmiar',
        customClass: 'Custom Class',
        methods: 'Funkcje',
        toolbarContent: 'Zawartość Paska Narzędzi',
      },
      widget: {
        tabTitle: 'Atrybuty',
        title: 'Tytuł',
        subTitle: 'Podtytuł',
        model: 'Model',
        name: 'Name',
        width: 'Szerokość',
        height: 'Wysokość',
        size: 'Rozmiar',
        labelWidth: 'Szerokość Etykiety',
        custom: 'Custom',
        placeholder: 'Placeholder',
        layout: 'Układ',
        block: 'Block',
        inline: 'Inline',
        contentPosition: 'Content Position',
        left: 'Lewa',
        right: 'Prawa',
        center: 'Środek',
        showInput: 'Display Input Box',
        min: 'Minimum',
        max: 'Maximum',
        step: 'Step',
        multiple: 'Multiple',
        filterable: 'Searchable',
        allowHalf: 'Allow Half',
        showAlpha: 'Support transparency options',
        showLabel: 'Show lable',
        option: 'Option',
        staticData: 'Static Data',
        remoteData: 'Remote Date',
        remoteFunc: 'Remote Function',
        value: 'Value',
        label: 'Tytuł',
        childrenOption: 'Sub-Option',
        defaultValue: 'Default Value',
        showType: 'Display type',
        isRange: 'Range Time',
        isTimestamp: 'Get time stamp',
        startPlaceholder: 'Placeholder of start time',
        endPlaceholder: 'Placeholder of end time',
        format: 'Format',
        uploadLimit: 'Maximum Upload Count',
        perPage: 'Liczba wierszy',
        isQiniu: 'Upload with Qiniu Cloud',
        tokenFunc: 'A funchtin to get Qiniu Uptoken',
        imageAction: 'Picture upload address',
        tip: 'Text Prompt',
        action: 'Upload Address',
        defaultType: 'Data Type',
        string: 'String',
        object: 'Object',
        array: 'Array',
        number: 'Number',
        boolean: 'Boolean',
        search: 'Search',
        tel: 'Tel',
        date: 'Date',
        time: 'Time',
        range: 'Range',
        color: 'Color',
        integer: 'Integer',
        float: 'Float',
        url: 'URL',
        email: 'E-mail',
        password: 'Password',
        hex: 'Hexadecimal',
        noGutter: 'Wyłącz odstępy siatki',
        columnOption: 'Ustawienie kolumn',
        itemsOption: 'Ustawienie listy',
        cols: 'cols',
        rowCols: 'Kolumn w wierszu',
        rowColsSm: 'Kolumn w wierszu (Sm)',
        rowColsMd: 'Kolumn w wierszu (Md)',
        rowColsLg: 'Kolumn w wierszu (Lg)',
        rowColsXl: 'Kolumn w wierszu (Xl)',
        offset: 'offset',
        order: 'order',
        sm: 'sm',
        md: 'md',
        lg: 'lg',
        xl: 'xl',
        justify: 'Horizontal Arrangement',
        justifyStart: 'Start',
        justifyEnd: 'End',
        justifyCenter: 'Wyśrodkuj',
        justifySpaceAround: 'Space Around',
        justifySpaceBetween: 'Space Between',
        type: 'Type',
        default: 'Default',
        card: 'Tabs',
        borderCard: 'Border-Card',
        tabPosition: 'Tab Position',
        top: 'Top',
        bottom: 'Bottom',
        tabOption: 'Label Configuration',
        tabName: 'Tab Name',
        customClass: 'Domyślne style',
        bodyClass: 't-body style',
        attribute: 'Attribute Action',
        dataBind: 'Data Binding',
        hidden: 'Hidden',
        readonly: 'Read Only',
        disabled: 'Disabled',
        editable: 'Text box is editable',
        clearable: 'Display Clear Button',
        arrowControl: 'Use the arrow for time selection',
        isDelete: 'Deletable',
        isEdit: 'Editable',
        showPassword: 'Display Password',
        validate: 'Validation',
        required: 'Required',
        patternPlaceholder: 'Fill in the regular expressions',
        newOption: 'New Option',
        tab: 'Tab',
        validatorRequired: 'Required',
        validatorType: 'Invaild format',
        validatorPattern: 'Unmatched pattern',
        showWordLimit: 'Show word limit',
        maxlength: 'Max length',
        hover: 'Podświetlenie wybranych',
        striped: 'Paskowany',
        selectable: 'Wybieralny',
        selectMode: 'Tryb wyboru',
        fields: 'Kolumny',
        sortBy: 'Sortuj wg',
        alignSelf: 'Wyrównanać siebie',
        alignContent: 'Wyrównanie treści',
        align: 'Wyrównanie',
        alignH: 'Wyrównanie poziome',
        alignV: 'Wyrównanie pionowe',
        alignTop: 'Top',
        alignMiddle: 'Middle',
        alignBottom: 'Bottom',
        alignAround: 'Around',
        alignBetween: 'Between',
        alignStart: 'Start',
        alignCenter: 'Center',
        alignEnd: 'End',
        alignBaseline: 'Baseline',
        alignStretch: 'Stretch',
        tag: 'Tag',
        fluid: 'Wypełnienie',
        keyNav: 'Nawigacja klawiszami',
        variant: 'Wariant',
        variants: {
          primary: 'Primary',
          secondary: 'Secondary',
          success: 'Success',
          danger: 'Danger',
          warning: 'Warning',
          info: 'Info',
          light: 'Light',
          dark: 'Dark',
          outlinePrimary: 'Outline primary',
          outlineSecondary: 'Outline secondary',
          outlineSuccess: 'Outline success',
          outlineDanger: 'Outline danger',
          outlineWarning: 'Outline warning',
          outlineInfo: 'Outline info',
          outlineLight: 'Outline light',
          outlineDark: 'Outline dark',
        },
        pill: 'Pill',
        squared: 'Squared',
        vertical: 'Vertical',
        accordion: 'Accordion',
        appear: 'Appear',
        visible: 'Widoczny',
        text: 'Text',
        dropleft: 'Drop left',
        dropright: 'Drop right',
        dropup: 'Drop up',
        noCaret: 'No caret',
        noFlip: 'No flip',
        split: 'Split',
      },
    },
    upload: {
      preview: 'preview',
      edit: 'replace',
      delete: 'delete',
    },
  },
  excelEditor: {
    first: 'Pierwszy',
    previous: 'Poprzedni',
    next: 'Następny',
    last: 'Ostatni',

    selected: 'Wybrany:',
    filtered: 'Przefiltrowane:',
    loaded: 'Załadowane:',

    processing: 'Przetwarzanie',
    tableSetting: 'Ustawienie tabeli',
    exportExcel: 'Export Excel',
    importExcel: 'Import Excel',
    back: 'Powrót',
    reset: 'Domyślne',
    sortingAndFiltering: 'Sortowanie i filtrowanie',
    sortAscending: 'Sortuj rosnąco',
    sortDescending: 'Sortuj malejąco',
    near: '≒ Blisko',
    exactMatch: '= Dokładnie równe',
    notMatch: '≠ Nie równe',
    greaterThan: '&gt; Więcej niż',
    greaterThanOrEqualTo: '≥ Więcej niż lub równe',
    lessThan: '&lt; Mniej niż',
    lessThanOrEqualTo: '≤ Mniej niż lub Równe',
    regularExpression: '~ Wyrażenie regularne',
    customFilter: 'Filtr dostosowany',

    apply: 'Zastosuj',
    noRecordIsRead: 'Żaden zapis nie jest odczytywany',
    readonlyColumnDetected: 'Kolumna tylko do odczytu została wykryta',

    noMatchedColumnName: 'Brak dopasowanej nazwy kolumny',
    invalidInputValue: 'Nieprawidłowa wartość wejściowa',
    missingKeyColumn: 'Brakująca kolumna klucza',
    noRecordIndicator: 'Brak zapisu',
  },
}
